/* 
Author: Sreenivassan R
Module: Login
*/
import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserFirstScreen from "../UI/UserFirstScreen";
import UserDetailScreen from "../UI/UserDetailScreen";
import classes from "./Login.module.css";
import CustomInput from "../UI/CustomInput";
import Button from "../UI/Button";
import Password from "../UI/Password";
import LoadingSpinner from "../UI/LoadingSpinner";
import { loginHandler } from "../../util/login";

function Login() {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);


  function submitHandler(event) {
    loginHandler(event, setIsLoading, loginData, setError, navigate);
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
    setError("")
  };

  const dataHandler = (e) => {
    const { name, value } = e.target;
    setLoginData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Fragment>
      <div className={classes.flexScreen}>
        <div className={classes.gridScreen}>
          <UserFirstScreen />
          <div className={classes.gridRight}>
            <div>
              <UserDetailScreen>
                <div>
                  <div className={classes.loginContainer}>
                    <div className={classes.userContents}>
                      <h2>Welcome Back</h2>
                      <p>
                        We've missed you! Please sign in to catch up on what
                        you've missed
                      </p>
                    </div>

                    <CustomInput
                      label="User Name"
                      type="text"
                      placeholder="User Name"
                      name="username"
                      value={loginData.username}
                      customInput={error === "User Not Found." ? classes.errorInput : ""}
                      onChange={dataHandler}
                      onKeyUp={handleKeyPress}
                    />
                    <Password
                      label="Password"
                      placeholder="Password"
                      customPasswordEye={classes.customPasswordEye}
                      name="password"
                      validation={error === "Password Incorrect." ? classes.errorInput : ""}
                      value={loginData.password}
                      onChange={dataHandler}
                      onKeyUp={handleKeyPress}
                    />
                    <div className={classes.forgotContainer}>
                      <Link
                        to="/forgotPassword"
                        className={classes.forgotpassword}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                    {error && <p className={classes.errorMessage}>{error}</p>}
                    <Button type="button" onClick={submitHandler} disabled={isLoading} customButtonWidth={classes.loginWidth}> {isLoading ? <LoadingSpinner/>  : 'Log in'}</Button>
                  </div>
                </div>
              </UserDetailScreen>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
