/* 
Author: Sreenivassan R
*/
import React, { useState } from "react";
import { Toolbar, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import classes from "./CustomToolDataGrid.module.css";
import Button from "./Button";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
function CustomToolDefault({
  permission,
  createFunction,
  lists,
  report,
  create,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  /* To get for the CSV and PDV print */
  const exportTable = async (mode) => {
    const columns = {
      users: [
        { dataKey: "name", title: "Name" },
        { dataKey: "empId", title: "Employee ID" },
        { dataKey: "role", title: "Roll" },
        { dataKey: "mobileno", title: "Mobile Number" },
        { dataKey: "email", title: "Email ID" },
        { dataKey: "username", title: "User Name" },
      ],
      role: [
        { dataKey: "no", title: "S No" },
        { dataKey: "role", title: "Roles" },
        { dataKey: "module", title: "Modules" },
        { dataKey: "users", title: "No of Users" },
        { dataKey: "status", title: "Status" },
      ],
      series: [
        { dataKey: "no", title: "S No" },
        { dataKey: "name", title: "Series Name" },
        { dataKey: "series", title: "Quantity" },
        { dataKey: "status", title: "Status" },
      ],
      catagory: [
        { dataKey: "no", title: "S No" },
        { dataKey: "categoryname", title: "Category" },
        { dataKey: "subcategoryname", title: "Sub Category" },
        { dataKey: "status", title: "Status" },
      ],
      notification: [
        { dataKey: "title", title: "Title" },
        { dataKey: "body", title: "Description" },
        { dataKey: "notificationType", title: "Notification Type" },
        { dataKey: "group", title: "Group" },
        { dataKey: "scheduledTime", title: "Schedule Time" },
      ],
      promotions: [
        { dataKey: "code", title: "Offer Code" },
        { dataKey: "description", title: "Offer Name" },
        { dataKey: "termsandconditions", title: "Description" },
        { dataKey: "discount", title: "Value" },
        { dataKey: "targetuser", title: "Target Audience" },
        { dataKey: "endOffer", title: "End Date & Time" },
      ],
      Faq: [
        { dataKey: "question", title: "Heading" },
        { dataKey: "answer", title: "Description" },
      ],
      HowtoPlay: [
        { dataKey: "heading", title: "Heading" },
        { dataKey: "innerHTML", title: "Description" },
      ],
      PrivacyPolicy: [
        { dataKey: "datetime", title: "Date" },
        { dataKey: "html", title: "Description" },
      ],
      TermsCondition: [
        { dataKey: "datetime", title: "Date" },
        { dataKey: "html", title: "Description" },
      ],
      sales: [
        { dataKey: "_id", title: "Date" },
        { dataKey: "TotalDraws", title: "No of Contest" },
        { dataKey: "TotalTicketSold", title: "No of Tickets" },
        { dataKey: "TotalRevenue", title: "TotalRevenue" },
        { dataKey: "TotallotteryPrize", title: "Prize Amount" },
        { dataKey: "NetAmount", title: "NetAmount" },
      ],
      lottery: [
        { dataKey: "name", title: "Lottery Name" },
        { dataKey: "resultdatetime", title: "Result Date" },
        { dataKey: "statename", title: "Province" },
        { dataKey: "ticketscount", title: "Total Tickets" },
        { dataKey: "ticketSold", title: "Tickets Purchased" },
        { dataKey: "cost", title: "Entry Fee" },
        { dataKey: "totalprize", title: "Total Winnings" },
        { dataKey: "ticketrevenue", title: "Revenue" },
      ],
      customer: [
        { dataKey: "username", title: "User Name" },
        { dataKey: "mobileno", title: "Phone Number" },
        { dataKey: "doj", title: "DOJ" },
        { dataKey: "wallet", title: "Balance" },
        { dataKey: "totalDeposit", title: "Total Deposit" },
        { dataKey: "totalWithdraw", title: "Total Withdraw" },
        { dataKey: "ticketrevenue", title: "Total Revenue" },
      ],
      prizedistribution: [
        { dataKey: "name", title: "Lottery Name" },
        { dataKey: "resultdatetime", title: "Result Date" },
        { dataKey: "winnerscount", title: "Number of Winner" },
        { dataKey: "prizedistribute", title: "Winnings" },
      ],
      finance: [
        { dataKey: "_id", title: "Month" },
        { dataKey: "TotalDraws", title: "No. of Lottries" },
        { dataKey: "TotalTickets", title: "Total Tickets" },
        { dataKey: "TotalTicketSold", title: "Sold Tickets" },
        { dataKey: "TotallotteryPrize", title: "Prize Amount" },
        { dataKey: "TotalRevenue", title: "Total Revenue" },
        { dataKey: "NetAmount", title: "Net Amount" },
      ],
      feedback: [
        { dataKey: "username", title: "User Name" },
        { dataKey: "postedat", title: "Date & Time" },
        { dataKey: "option", title: "FeedBack Type" },
        { dataKey: "feedback", title: "Description" },
        { dataKey: "status", title: "Status" },
      ],
      ticket: [
        { dataKey: "name", title: "Lottery Name" },
        { dataKey: "ticketno", title: "Ticket Number" },
        { dataKey: "buyername", title: "User Name" },
        { dataKey: "buydatetime", title: "Purchase Time" },
        { dataKey: "resultdatetime", title: "Draw Time" },
        { dataKey: "cost", title: "Amount" },
      ],
      feedbacktype: [
        { dataKey: "no", title: "S No" },
        { dataKey: "option", title: "Options" },
        { dataKey: "mediaEnable", title: "Media Enable" },
        { dataKey: "status", title: "Status" },
      ],
      price: [
        { dataKey: "no", title: "S No" },
        { dataKey: "cost", title: "Cost" },
        { dataKey: "color", title: "Colour" },
        { dataKey: "status", title: "Status" },
      ],
      Log: [
        { dataKey: "username", title: "Admin Name" },
        { dataKey: "mobileno", title: "Mobile No" },
        { dataKey: "address", title: "Address" },
        { dataKey: "adhaarno", title: "Adhaar No" },
        { dataKey: "empId", title: "Employee Id" },
        { dataKey: "role", title: "User Name" },
        { dataKey: "logmode", title: "Log Mode" },
        { dataKey: "username", title: "User" },
      ],
    };
    console.log({ report });
    console.log({ lists });
    const reportsalesList = lists;

    console.log({ reportsalesList });
    if (mode == "pdf") {
      var header = {};
      columns[report].map((cols) => {
        header[cols.title] = cols.dataKey;
      });
      console.log(Object.keys(header));
      const dataSet = reportsalesList.map((row, index) => {
        var newObje = {};
        Object.values(header).map((col) => {
          if (col === "no") {
            // Set serial number starting from 1 to n
            newObje[col] = index + 1;
          } else if (col === "mediaEnable" || col === "status") {
            // Set "Enabled" or "Disabled" based on mediaEnable value
            newObje[col] = row[col] ? "Enabled" : "Disabled";
          } else {
            newObje[col] = row[col];
          }
        });
        return newObje;
      });
      console.log(dataSet);

      const doc = new jsPDF();

      autoTable(doc, {
        columns: columns[report],
        body: dataSet,
        didDrawPage: (dataArg) => {
          doc.text(`${report} Report`, 10, 10);
        },
      });

      // Save the PDF
      doc.save(`${report} Report_${Date.now()}.pdf`);
    } else {
      var header = {};
      columns[report].forEach((cols) => {
        header[cols.title] = cols.dataKey;
      });
      var data = [];
      reportsalesList.forEach((row, index) => {
        var newSet = [];
        Object.values(header).forEach((head) => {
          let val = row[head];
          if (head === "no") {
            // Set serial number starting from 1 to n
            val = index + 1;
          } else if (head === "mediaEnable" || head === "status") {
            // Set "Enabled" or "Disabled" based on mediaEnable value
            val = row[head] ? "Enabled" : "Disabled";
          }
          newSet.push(val);
        });
        data.push(newSet);
      });
      const csvData = [Object.keys(header), ...data];
      const csv = Papa.unparse(csvData);
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${report} Report_${Date.now()}.csv`;
      link.click();
    }
  };
  return (
    <Toolbar className={classes.toolPadding}>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        <div className={classes.customFlexDefault}>
          <div className={classes.buttonFlex}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <div className={classes.buttonFlex}>
                <Button
                  id="basic-button"
                  className="datagrid-export-button"
                  aria-controls={open1 ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? "true" : undefined}
                  onClick={handleClick}
                  customButton="ss"
                  customButtonWidth={classes.exportButton}
                >
                  Export
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open1}
                  onClose={handleClose1}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => exportTable("pdf")}>PDF</MenuItem>
                  <MenuItem onClick={() => exportTable("csv")}>CSV</MenuItem>
                </Menu>
              </div>
            </Typography>
            {permission && permission.add && (
              <Button
                customButtonWidth={classes.customToolButton}
                onClick={createFunction}
              >
                Create
              </Button>
            )}
          </div>
        </div>
      </Typography>
    </Toolbar>
  );
}

export default CustomToolDefault;
