/* 
Author: Sreenivassan R
Module: Tearms and Condition Create
*/
import React, { useEffect, useState } from "react";
import ModalComponent from "../UI/ModalComponent";
import classes from "../CreateModal.module.css";
import { useNavigate } from "react-router-dom";
import QuillEditor from "../UI/QuillEditor";
import Button from "../UI/Button";
import { toast } from "react-toastify";
import { createTermsData } from "../../util/terms";
import TextDescription from "../UI/TextDescription";

function TermsCreateModal({
  Hide,
  updateTermsDataGet,
  id,
  notifysuccess,
  notifyfail,
  setRender,
}) {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [termsData, setTermsData] = useState({
    html: "",
  });

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      if (id) {
        console.log(id);
        setTermsData(updateTermsDataGet);
        console.log(updateTermsDataGet);
      }
    }
  }, [updateTermsDataGet]);

  function howtoplayHandler() {
    createTermsData(
      authToken,
      setTermsData,
      setIsSubmitting,
      id,
      termsData,
      validateForm,
      setValidationErrors,
      toast,
      notifysuccess,
      Hide,
      setRender
    );
  }

  const validateForm = (termsData) => {
    const errors = {};
    errors.html = !termsData.html ? "Please enter a Description" : "";

    return errors;
  };


  /* Getting Description Input */
  const handleContentChange = (event) => {
    /*  Replace all "/" characters with "\/" */
    const updatedContent = event.target.value;

    /* Update the state with the modified content */
    setTermsData((prevState) => ({
      ...prevState,
      html: updatedContent,
    }));
  };

  const title = id ? "Edit" : "Create";

  return (
    <ModalComponent Hide={Hide}>
      <h4 className={classes.h4Editor}>{title} Terms and Condition</h4>

      <div className={classes.editorStyle}>
         <TextDescription
          lable="Description"
          name="html"
          onChange={handleContentChange}
          value={termsData.html}
        />
      </div>
      <div className={classes.buttonWrap}>
        <Button
          type="button"
          customButtonWidth={classes.button}  
          disabled={isSubmitting}
          onClick={howtoplayHandler}
        >
          {isSubmitting ? "Submitting..." : "Save"}
        </Button>
      </div>
    </ModalComponent>
  );
}

export default TermsCreateModal;
