/* 
Author: Sreenivassan R
*/
import React, { useEffect, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import classes from "./LotteryUserManagement.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../Toast";
import { toast } from "react-toastify";
import { fetchUserData, userDelete } from "../../util/userManagement";
import DataGridDefault from "../UI/DataGridDefault";
import { Tooltip } from "@mui/material";

function LotteryUserManagement() {
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [userData, setUserData] = useState([]);
  const [genToken, setGenToken] = useState()
  const routerstate = useLocation();
  let permission = routerstate.state;
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const notifysuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyfail = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  let columns = [
    { field: "name", headerName: "Name",flex:1 },
    { field: "empId", headerName: "Employee ID", flex:1 },
    { field: "role", headerName: "Roll", flex:1 },
    { field: "mobileno", headerName: "Mobile Number",flex:1 },
    { field: "email", headerName: "Email ID", flex:2 },
    { field: "username", headerName: "User Name", flex:1 },
  ];
  if (permission && permission.edit && permission.delete) {
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "",
        flex:1,
        cellClassName: "actions",
        getActions: (val) => {
          var row = val.row;
          return [
            <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditIcon />
              </Tooltip>
            }
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(row)}
              color="inherit"
            />,
            <GridActionsCellItem
            icon={
              <Tooltip title="Delete">
                <DeleteIcon />
              </Tooltip>
            }
              label="Delete"
              onClick={() => handleDeleteClick(val.row)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  } else if (permission && permission.edit && !permission.delete) {
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 150,
        cellClassName: "actions",
        getActions: (val) => {
          var row = val.row;
          return [
            <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditIcon />
              </Tooltip>
            }
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(row)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  } else if (permission && !permission.edit && permission.delete) {
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 150,
        cellClassName: "actions",
        getActions: (val) => {
          //var id = lottId.row.id;

          return [
            <GridActionsCellItem
            icon={
              <Tooltip title="Delete">
                <DeleteIcon />
              </Tooltip>
            }
              label="Delete"
              onClick={() => handleDeleteClick(val.row)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  }
  const handleEditClick = async (row) => {
    console.log(row);
    navigate("/Root/LotteryUserAddList", { state: { id: row.id, permission } });
  };

  function handleDeleteClick(row) {
    userDelete(row, authToken, notifyfail, notifysuccess, setRender);
  }

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      setLoading(true);
      fetchUserData(setUserData, authToken);
      setLoading(false);
    }
  }, [render, authToken]);

  function createUser() {
    if (permission) {
      navigate("/Root/LotteryUserAddList", { state: { permission } });
    }
  }

  return (
    <div className={classes.GridWrapper}>

      <DataGridDefault
         heading="User List"
         rows={userData}
         columns={columns}
         loading={loading}
         genToken={genToken}
         permission={permission}
         createFunction={createUser}
         date={false}
         exportBtn={true}
         sortField={"empId"}
         lists={userData}
         report="users"
      />
      <Toast />
    </div>
  );
}

export default LotteryUserManagement;
