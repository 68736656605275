/* 
Author: Sreenivassan R
*/
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import RootLayout from "./component/RootLayout";
import CatagoryList from "./component/CatagoryManagement/CatagoryList";
import ResultList from "./component/ResultManagement/ResultList";
import ResultDetails from "./component/ResultManagement/ResultDetails";
import RoleManagement from "./component/RoleManagement/RoleManagement";
import Profile from "./component/Profile/Profile";
import KycList from "./component/KycManagement/KycList";
import SalesReport from "./component/Report/SalesReport";
import NotificationMangement from "./component/NotificationManagement/NotificationMangement";
import FaqDetail from "./component/FAQ Managemnet/FaqDetail";
import HowToPlayDetails from "./component/HowToPlayManagement/HowToPlayDetails";
import PromotionsManagement from "./component/Promotions&Offers/PromotionsManagement";
import TermsManagement from "./component/TermsManagement/TermsManagement";
import PrivacyManagement from "./component/PrivacyPolicyManagement/PrivacyManagement";
import Dashboard from "./component/DashBoard/Dashboard";
import Logs from "./component/Logs/Logs";
import LotteryUserManagement from "./component/User Management/LotteryUserManagement";
import LotteryManagementAlpha from "./component/Lottery Management/LotteryManagementAlpha";
import Forgotpassword from "./component/Login/Forgotpassword";
import ResetPassword from "./component/Login/ResetPassword";
import Otp from "./component/Login/Otp";
import LotteryManagement from "./component/Lottery Management/LotteryManagement";
import Login from "./component/Login/Login";
import LotteryUserManagementAdd from "./component/User Management/LotteryUserManagementAdd";
import SeriesList from "./component/SeriesManagement/SeriesList";
import FeedBackList from "./component/FeedBackTypes/FeedbackList";
import PriceList from "./component/Price Management/PriceList";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgotPassword",
    element: <Forgotpassword />,
  },
  {
    path: "/resetPassword/:id",
    element: <ResetPassword />,
  },
  {
    path: "/otp/:id",
    element: <Otp />,
  },
  {
    path: "/Root",
    element: <RootLayout />,
    children: [
      {
        path: "Dashboard",
        element: <Dashboard />,
      },
      {
        path: "LotteryManagement",
        element: <LotteryManagement />,
      },
      {
        path: "LotteryAddList",
        element: <LotteryManagementAlpha />,
      },
      {
        path: "LotteryUserAddList",
        element: <LotteryUserManagementAdd />,
      },
      {
        path: "LotteryUserAdd",
        element: <LotteryUserManagement />,
      },
      {
        path: "CatagoryList",
        element: <CatagoryList />,
      },
      {
        path: "PriceList",
        element: <PriceList />,
      },
      {
        path: "SeriesList",
        element: <SeriesList />,
      },
      {
        path: "ResultList",
        element: <ResultList />,
      },
      {
        path: "ResultDetail",
        element: <ResultDetails />,
      },
      {
        path: "RoleManagement",
        element: <RoleManagement />,
      },
      {
        path: "Profile",
        element: <Profile />,
      },
      {
        path: "KycList",
        element: <KycList />,
      },
      {
        path: "Logs",
        element: <Logs />,
      },
      {
        path: "NotificationList",
        element: <NotificationMangement />,
      },
      {
        path: "PromotionsManagement",
        element: <PromotionsManagement />,
      },
      {
        path: "faq",
        element: <FaqDetail />,
      },
      {
        path: "howtoplay",
        element: <HowToPlayDetails />,
      },
      {
        path: "termsandcondition",
        element: <TermsManagement />,
      },
      {
        path: "privacypolicy",
        element: <PrivacyManagement />,
      },
      {
        path: "FeedBackType",
        element: <FeedBackList/>,
      },
      {
        path: "SalesReport",
        element: <SalesReport report="sales" />,
      },
      {
        path: "LotteryReport",
        element: <SalesReport report="lottery" />,
      },
      {
        path: "CustomerReport",
        element: <SalesReport report="customer" />,
      },
      {
        path: "PrizeDistributionReport",
        element: <SalesReport report="prizedistribution" />,
      },
      {
        path: "FinancialSummary",
        element: <SalesReport report="finance" />,
      },
      {
        path: "UserFeedBack",
        element: <SalesReport report="feedback" />,
      },
      {
        path: "TicketPurchase",
        element: <SalesReport report="ticket" />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
