/* 
Author: Sreenivassan R
*/
const ADMINURL = process.env.REACT_APP_ADMINURL;
export const fetchFaqData = async (
  authToken,
  setFaqDataGet,
  setLoading,
  notifyfail
) => {
  try {
    setLoading(true);
    const response = await fetch(ADMINURL + "/admin/faqs", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    var i = 1;
    console.log("Customer", responseData.data);

    const faqDataGet = responseData.data.map((item, index) => {
      return {
        uniqueId: `row_${index}`,
        id: item._id,
        _id: item._id,
        question: item.question,
        answer: item.answer,
      };
    });

    setFaqDataGet(faqDataGet);
    setLoading(false);
    console.log("faqDataGet", faqDataGet);
    console.log("Data submitted successfully:", faqDataGet);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

export const createFaqData = async function faqHandler(
  authToken,
  setFaqData,
  setIsSubmitting,
  id,
  faqData,
  validateForm,
  setValidationErrors,
  toast,
  notifysuccess,
  notifyfail,
  Hide,
) {
  const errors = validateForm(faqData);

  if (Object.values(errors).some((va) => va !== "")) {
    setValidationErrors(errors);
    console.log("Form Data:", faqData);
    return;
  } else {
    setFaqData((prevFormData) => ({
      ...prevFormData,
    }));
  }
  try {
    setIsSubmitting(true);
    var postdata = id
      ? {
          id: id,
          mode: "update",
        }
      : {
          mode: "insert",
        };
    // Log the mode value
    const response = await fetch(ADMINURL + "/admin/faqs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ ...faqData, ...postdata }),
    });
    const responseData = await response.json();
    console.log("loteryList", responseData);
    setIsSubmitting(false);
    if (responseData.sts === "0") {
      notifysuccess();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    if (responseData.sts === "1") {
      notifyfail(responseData.msg);
    }
    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    if (responseData.sts === "1") {
      const notifyfail = () =>
        toast.error(responseData.msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      notifyfail();
      return;
    }
    console.log("Data submitted successfully:", responseData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

export const faqDelete = async function handleDeleteClick(
  id,
  authToken,
  notifysuccess,
  notifyfail
) {
  console.log(id);
  const isConfirmed = window.confirm("Do You Want to Delete this Faq?");
  if (isConfirmed) {
    try {
      const response = await fetch(ADMINURL + "/admin/faqs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ id: id, mode: "delete" }),
      });
      console.log("response categery", response);
      const responseData = await response.json();
      if (responseData.sts === "1") {
        notifyfail(responseData.msg);
      }
      if (responseData.sts === "0") {
        notifysuccess();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  }
};
