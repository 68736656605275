/* 
Author: Sreenivassan R
*/
import React from "react";
import classes from "./ModileInput.module.css";

function MobileInput({
  label,
  name,
  onChange,
  value,
  err,
  validation,
  placeholder,
}) {
  return (
    <div className={classes.mobileInput}>
      <label>{label}</label>
      <div className={`${classes.mobileInputFlex} ${validation}`}>
        <div className={classes.preNumber}>+91</div>
        <input
          type="number"
          name={name}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          onInput={(e) => {
            if (e.target.value.length > 10) {
              e.target.value = e.target.value.slice(0, 10);
            }
          }}
        />
      </div>
      {err ? (
        <span className={classes.errorMsg}>
          This {label} field is required.
        </span>
      ) : (
        ""
      )}
    </div>
  );
}

export default MobileInput;
