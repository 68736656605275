/* 
Author: Manickam S, Sreenivassan R
Module: Report
*/
import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import classes from "./Report.module.css";
import { useNavigate } from "react-router-dom";
import DateFilter from "../DateFilter";
import Button from "../UI/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import Grid from "@mui/material/Grid";
import { Select } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import MuiButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
import DataGridDefault from "../UI/DataGridDefault";
const ADMINURL = process.env.REACT_APP_ADMINURL;
function SalesReport({ report }) {
  const [open, setOpen] = useState(false);
  const [modalStyle, setModalStyle] = useState({});
  const buttonRef = useRef(null);
  const [salesList, setSalesList] = useState([]);

  const [showfilter, setShowfilter] = useState(false);

  const [pageLength, setPageLength] = useState(0);
  const [shouldGetPageLength, setShouldGetPageLength] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [value, setValue] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, field, index) => {
    setFilter((prev) => ({ ...prev, temp: !prev.temp }));
    setFilterval((prev) => {
      prev[field][index]["value"] = event.target.value;
      console.log(index, field, prev);
      return prev;
    });
  };

  const conditionChange = (event, field, index) => {
    setFilter((prev) => ({ ...prev, temp: !prev.temp }));
    setFilterval((prev) => {
      prev[field][index]["condition"] = event.target.value;
      console.log(index, field, prev);
      return prev;
    });
  };
  const addRuleFn = (field) => {
    setFilter((prev) => ({ ...prev, temp: !prev.temp }));
    setFilterval((prev) => {
      prev[field].push({ condition: "", value: "" });
      //console.log(index,field,prev);
      return prev;
    });
  };
  const [loading, setLoading] = useState(false);

  const authToken = localStorage.getItem("token");

  let currentDate = new Date();
  let currentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  console.log(currentDate);

  var reportFilterState = {
    sales: {
      date: false,
      TotalDraws: false,
      TotalTicketSold: false,
      TotalRevenue: false,
      TotallotteryPrize: false,
      NetAmount: false,
      temp: true,
    },
    customer: {
      date: false,
      username: false,
      mobileno: false,
      wallet: false,
      totalDeposit: false,
      totalWithdraw: false,
      ticketrevenue: false,
      temp: true,
    },
    lottery: {
      date: false,
      name: false,
      province: false,
      ticketscount: false,
      ticketSold: false,
      cost: false,
      totalprize: false,
      ticketrevenue: false,
      temp: true,
    },
    finance: {
      date: false,
      TotalDraws: false,
      TotalTickets: false,
      TotalTicketSold: false,
      TotallotteryPrize: false,
      TotalRevenue: false,
      NetAmount: false,
      temp: true,
    },
    prizedistribution: {
      date: false,
      name: false,
      prizedistribute: false,
      winnerscount: false,
      temp: false,
    },
    feedback: {
      username: false,
      feedbacktype: false,
      feedback: false,
      date: false,
      temp: false,
    },
    ticket: {
      name: false,
      ticketno: false,
      buyername: false,
      buydatetime: false,
      resultdatetime: false,
      cost: false,
      temp: true,
    },
  };
  const serchfiledsForReports = {
    sales: [],
    customer: ["username", "mobileno"],
    lottery: ["name", "province"],
    finance: [],
    prizedistribution: ["name"],
    feedback: ["feedback", "username", "feedbacktype"],
    ticket: ["buyername", "ticketno"],
  };
  const [filter, setFilter] = React.useState(reportFilterState[report]);
  const [focus, setFocus] = React.useState("");
  const [initiate, setInitiate] = React.useState(false);
  const navigate = useNavigate();
  const handleFilterIconClick = (event, params, column, header) => {
    const buttonPosition = event.currentTarget.getBoundingClientRect();
    setFilter((prev) => {
      var keys = Object.keys(prev);
      var values = Object.values(prev);
      var trueIndex = values.indexOf(true);
      var truekey = keys[trueIndex];
      if (truekey) {
        return { ...prev, [truekey]: false, [column]: true };
      } else {
        return { ...prev, [column]: true };
      }
    });
    console.log(window.scrollX, buttonPosition);
    var left =
      window.innerWidth < buttonPosition.left + 450
        ? window.innerWidth - 450
        : buttonPosition.left;
    console.log({ left });
    setValue(header);
    setOpen(true);
    setModalStyle({
      position: "absolute",
      top: `${buttonPosition.bottom + window.scrollY}px`,
      left: `${left}px`,
      transform: "translate(0%, 0%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: 24,
      borderRadius: "12px",
      minHeight: "150px",
      p: 3,
    });
    setFocus(column);
  };

  const handleClose = () => setOpen(false);
  let reportColumns = {
    sales: [
      {
        field: "date",
        headerName: "Date",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Date "}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "date", "Date")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },

      {
        field: "TotalDraws",
        headerName: "No of Contest",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"No of Contest "}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "TotalDraws",
                    "No of Contest"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "TotalTicketSold",
        headerName: "No of Tickets",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"No of Tickets "}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "TotalTicketSold",
                    "No of Tickets"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
        width: 200,
      },
      {
        field: "TotalRevenue",
        headerName: "Total Revenue",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Total Revenue "}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "TotalRevenue",
                    "Total Revenue"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
        width: 200,
      },
      {
        field: "TotallotteryPrize",
        headerName: "Prize Amount",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Prize Amount "}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "TotallotteryPrize",
                    "Prize Amount"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
        width: 200,
      },
      {
        field: "NetAmount",
        headerName: "Net Amount",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Net Amount "}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "NetAmount",
                    "Net Amount"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
        width: 200,
      },
    ],
    customer: [
      {
        field: "username",
        headerName: "User Name",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"User Name "}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "username", "User Name")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "mobileno",
        headerName: "Phone Number",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Phone Number "}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "mobileno",
                    "Phone Number"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "doj",
        headerName: "DOJ",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"DOJ "}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "date", "DOJ")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "balance",
        headerName: "Balance",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Balance "}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "wallet", "Balance")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "totalDeposit",
        headerName: "Total Deposit",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Total Deposit "}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "totalDeposit",
                    "Total Deposit"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "totalWithdraw",
        headerName: "Total Withdraw",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Total Withdraw"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "totalWithdraw",
                    "Total Withdraw"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "ticketrevenue",
        headerName: "Revenue",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Revenue"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "ticketrevenue",
                    "Revenue"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
    ],
    lottery: [
      {
        field: "name",
        headerName: "Lottery Name",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Lottery Name"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "name", "Lottery Name")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "date",
        headerName: "Result Date",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Result Date"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "date", "Result Date")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "province",
        headerName: "Province",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Province"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "province", "Province")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "ticketscount",
        headerName: "Total Tickets",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Total Tickets"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "ticketscount",
                    "Total Tickets"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "ticketSold",
        headerName: "Tickets Purchased",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Tickets Purchased"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "ticketSold",
                    "Tickets Purchased"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "cost",
        headerName: "Entry Fee",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Entry Fee"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "cost", "Entry Fee")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "totalprize",
        headerName: "Total Winnings",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Total Winnings"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "totalprize",
                    "Total Winnings"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "ticketrevenue",
        headerName: "Revenue",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Revenue"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "ticketrevenue",
                    "Revenue"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
    ],
    finance: [
      {
        field: "date",
        headerName: "Month",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Month"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "date", "Month")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "TotalDraws",
        headerName: "No.of Lottries",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"No.of Lottries"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "TotalDraws",
                    "No.of Lottries"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "TotalTickets",
        headerName: "Total Tickets",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Total Tickets"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "TotalTickets",
                    "Total Tickets"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "TotalTicketSold",
        headerName: "Sold Tickets",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Sold Tickets"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "TotalTicketSold",
                    "Sold Tickets"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "TotallotteryPrize",
        headerName: "Prize Amount",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Prize Amount"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "TotallotteryPrize",
                    "Prize Amount"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "TotalRevenue",
        headerName: "Total Revenue",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Total Revenue"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "TotalRevenue",
                    "Total Revenue"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },

      {
        field: "NetAmount",
        headerName: "Net Amount",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Net Amount"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "NetAmount",
                    "Net Amount"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
    ],
    prizedistribution: [
      {
        field: "name",
        headerName: "Lottery Name",
        flex: 1,
        renderCell: (params, name) => (
          <div className={classes.Flex}>
            <span className={classes.lottText}>{params.row.name}</span>
            <span className={classes.idText}>{params.row.id}</span>
          </div>
        ),
        renderHeader: (params) => (
          <strong>
            {"Lottery Name"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "name", "Lottery Name")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "date",
        headerName: "Date",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Result Date "}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "date", "Date")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "prizedistribute",
        headerName: "Number of Winners",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Number of Winners"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "prizedistribute",
                    "Number of Winners"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "winnerscount",
        headerName: "Winnings",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Winnings"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "winnerscount",
                    "Winnings"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
    ],
    feedback: [
      {
        field: "username",
        headerName: "User Name",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"User Name"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "username", "User Name")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "postedat",
        headerName: "Date & Time",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Date & Time"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "date", "Date & Time")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "feedbacktype",
        headerName: "FeedBack Type",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"FeedBack Type"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "feedbacktype",
                    "FeedBack Type"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "feedback",
        headerName: "Description",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Description"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "feedback",
                    "Description"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "feedbackimage",
        headerName: "Feedback Image",
        flex: 1,
        renderHeader: (params) => <strong>{"Feedback Image"}</strong>,
        renderCell: (params) => {
          console.log({ params });
          if (params.row.feedbackimage) {
            return (
              <img
                src={params.row.feedbackimage}
                alt="Image"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            );
          } else {
            return <p></p>;
          }
        },
      },

      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderHeader: (params) => <strong>{"Status"}</strong>,
      },
    ],
    ticket: [
      {
        field: "name",
        headerName: "Lottery Name",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Lottery Name"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "name", "Lottery Name")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "ticketno",
        headerName: "",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Ticket Number"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "ticketno",
                    "Ticket Number"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "buyername",
        headerName: "User Name",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"User Name"}
            <Tooltip title="User Name">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(event, params, "buyername", "User Name")
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "buydatetime",
        headerName: "Purchase Time",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Purchase Time"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "buydatetime",
                    "Purchase Time"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "resultdatetime",
        headerName: "Draw Time",
        flex: 1,
        renderHeader: (params) => (
          <strong>
            {"Draw Time"}
            <Tooltip title="Filter list">
              <IconButton
                onClick={(event) =>
                  handleFilterIconClick(
                    event,
                    params,
                    "resultdatetime",
                    "Draw Time"
                  )
                }
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </strong>
        ),
      },
      {
        field: "cost",
        headerName: "Amount",
        flex: 1,
        renderHeader: (params) => <strong>{"Amount"}</strong>,
      },
    ],
  };

  let options = {
    date: "Date",
    TotalDraws: "number",
    TotalTicketSold: "number",
    TotalRevenue: "number",
    TotallotteryPrize: "number",
    NetAmount: "number",
  };
  let reportState = {
    sales: {
      date: [{ start: currentMonth, end: currentDate }],
      TotalDraws: [{ condition: "", value: "" }],
      TotalTicketSold: [{ condition: "", value: "" }],
      TotalRevenue: [{ condition: "", value: "" }],
      TotallotteryPrize: [{ condition: "", value: "" }],
      NetAmount: [{ condition: "", value: "" }],
    },
    customer: {
      date: [{ start: currentMonth, end: currentDate }],
      username: [{ condition: "", value: "" }],
      mobileno: [{ condition: "", value: "" }],
      wallet: [{ condition: "", value: "" }],
      totalDeposit: [{ condition: "", value: "" }],
      totalWithdraw: [{ condition: "", value: "" }],
      ticketrevenue: [{ condition: "", value: "" }],
    },
    lottery: {
      date: [{ start: currentMonth, end: currentDate }],
      name: [{ condition: "", value: "" }],
      province: [{ condition: "", value: "" }],
      ticketscount: [{ condition: "", value: "" }],
      ticketSold: [{ condition: "", value: "" }],
      cost: [{ condition: "", value: "" }],
      totalprize: [{ condition: "", value: "" }],
      ticketrevenue: [{ condition: "", value: "" }],
    },
    finance: {
      date: [{ start: currentMonth, end: currentDate }],
      TotalDraws: [{ condition: "", value: "" }],
      TotalTickets: [{ condition: "", value: "" }],
      TotalTicketSold: [{ condition: "", value: "" }],
      TotallotteryPrize: [{ condition: "", value: "" }],
      TotalRevenue: [{ condition: "", value: "" }],
      NetAmount: [{ condition: "", value: "" }],
    },
    prizedistribution: {
      date: [{ start: currentMonth, end: currentDate }],
      name: [{ condition: "", value: "" }],
      prizedistribute: [{ condition: "", value: "" }],
      winnerscount: [{ condition: "", value: "" }],
    },
    feedback: {
      date: [{ start: currentMonth, end: currentDate }],
      username: [{ condition: "", value: "" }],
      feedbacktype: [{ condition: "", value: "" }],
      feedback: [{ condition: "", value: "" }],
    },
    ticket: {
      name: [{ start: currentMonth, end: currentDate }],
      ticketno: [{ condition: "", value: "" }],
      buyername: [{ condition: "", value: "" }],
      buydatetime: [{ condition: "", value: "" }],
      resultdatetime: [{ condition: "", value: "" }],
      cost: [{ condition: "", value: "" }],
    },
  };
  const [filterval, setFilterval] = React.useState(reportState[report]);
  const today = new Date();
  console.log({ report, state: reportState[report] });
  useEffect(() => {
    console.log({ report, state: reportState[report] });
    setFilter(reportFilterState[report]);
    setFilterval(reportState[report]);
  }, [report]);
  const firstDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );
  console.log(firstDayOfCurrentMonth);
  const startDateISO = `${firstDayOfCurrentMonth.getFullYear()}-${(
    firstDayOfCurrentMonth.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${firstDayOfCurrentMonth
    .getDate()
    .toString()
    .padStart(2, "0")}T00:00:00`;
  const endDateISO = today.toISOString();

  const fetchSaleReport = async (page, isPrint = false) => {
    var reportENdPoints = {
      sales: "sales",
      customer: "customer",
      lottery: "lottery",
      finance: "financial",
      prizedistribution: "prizedistribution",
      feedback: "feedback",
      ticket: "purchasereport",
    };
    try {
      const response = await fetch(
        ADMINURL + `/report/${reportENdPoints[report]}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            filterval,
            isPrint,
            start: startDateISO,
            end: startDateISO,
            size: 5,
            page: page,
            getPageLength: shouldGetPageLength,
          }),
        }
      );
      const responseData = await response.json();
      console.log(responseData, "reportSale");
      if (!response.ok) {
        throw new Error(
          responseData.message || "Failed to submit data to the server"
        );
      }

      console.log("SaleReport", responseData.data.report[0]);
      const salesList = responseData.data.report.map((item, index) => {
        if (report == "sales") {
          const startDatetime = new Date(item._id);

          const startdateOptions = {
            timeZone: "UTC",
            day: "2-digit",
            month: "numeric",
            year: "numeric",
          };

          const formattedStartDate = startDatetime.toLocaleDateString(
            "en-GB",
            startdateOptions
          );
          console.log("formattedStartDate", formattedStartDate);
          let pageLength = responseData.data.pageLength;
          if (shouldGetPageLength) {
            setPageLength(pageLength); // Update pageLength only when shouldGetPageLength is true
          }
          return {
            id: `row_${index}`,
            date: `${formattedStartDate}`,
            NetAmount: item.NetAmount,
            TotalDraws: item.TotalDraws,
            TotalRevenue: item.TotalRevenue,
            TotalTicketSold: item.TotalTicketSold,
            TotallotteryPrize: item.TotallotteryPrize,
          };
        } else if (report == "customer") {
          const dateofJoin = new Date(item.doj);

          const joindateOptions = {
            timeZone: "UTC",
            month: "numeric",
            year: "numeric",
            day: "2-digit",
          };
          const formattedJointDate = dateofJoin.toLocaleDateString(
            "en-GB",
            joindateOptions
          );

          const balance = parseInt(item.wallet);

          const totalWithdraw = parseInt(item.totalWithdraw);
          const totalDeposit = parseInt(item.totalDeposit);
          const revenue = parseInt(item.ticketrevenue);

          let pageLength = responseData.data.pageLength;
          if (shouldGetPageLength) {
            setPageLength(pageLength); // Update pageLength only when shouldGetPageLength is true
          }
          return {
            id: `row_${index}`,
            username: item.username,
            mobileno: item.mobileno,
            doj: `${formattedJointDate}`,
            balance: balance,
            totalDeposit: item.totalDeposit,
            totalWithdraw: item.totalWithdraw,
            ticketrevenue: revenue,
          };
        } else if (report == "lottery") {
          let pageLength = responseData.data.pageLength;
          if (shouldGetPageLength) {
            setPageLength(pageLength); // Update pageLength only when shouldGetPageLength is true
          }
          const resultdatetime = new Date(item.resultdatetime);

          const resultdatetimeOptions = {
            timeZone: "UTC",
            month: "numeric",
            year: "numeric",
            day: "2-digit",
          };
          const formattedResultDate = resultdatetime.toLocaleDateString(
            "en-GB",
            resultdatetimeOptions
          );
          return {
            id: `row_${index}`,
            date: formattedResultDate,
            name: item.name,
            province: item.state.statename,
            ticketscount: item.ticketscount,
            ticketSold: item.ticketSold,
            cost: item.cost,
            totalprize: item.totalprize,
            ticketrevenue: item.ticketrevenue,
          };
        } else if (report == "finance") {
          const startDatetime = new Date(item._id);

          const startdateOptions = {
            month: "long",
          };

          const formattedStartDate = startDatetime.toLocaleDateString(
            "en-GB",
            startdateOptions
          );

          let pageLength = responseData.data.pageLength;
          if (shouldGetPageLength) {
            setPageLength(pageLength); // Update pageLength only when shouldGetPageLength is true
          }
          return {
            id: `row_${index}`,
            date: `${formattedStartDate}`,
            NetAmount: item.NetAmount,
            TotalDraws: item.TotalDraws,
            TotalTickets: item.TotalTickets,
            TotalRevenue: item.TotalRevenue,
            TotalTicketSold: item.TotalTicketSold,
            TotallotteryPrize: item.TotallotteryPrize,
          };
        } else if (report == "prizedistribution") {
          let pageLength = responseData.data.pageLength;
          if (shouldGetPageLength) {
            setPageLength(pageLength); // Update pageLength only when shouldGetPageLength is true
          }
          const resultdatetime = new Date(item.resultdatetime);

          const resultdatetimeOptions = {
            timeZone: "UTC",
            month: "numeric",
            year: "numeric",
            day: "2-digit",
          };
          const formattedResultDate = resultdatetime.toLocaleDateString(
            "en-GB",
            resultdatetimeOptions
          );
          return {
            id: `row_${index}`,
            id: item.id,
            name: item.name,
            date: formattedResultDate,
            prizedistribute: item.prizedistribute,
            winnerscount: item.winnerscount,
          };
        } else if (report == "feedback") {
          const postedatformat = new Date(item.postedat);

          const joindateOptions = {
            timeZone: "UTC",
            month: "numeric",
            year: "numeric",
            day: "2-digit",
          };
          const formattedDate = postedatformat.toLocaleDateString(
            "en-GB",
            joindateOptions
          );

          let pageLength = responseData.data.pageLength;
          if (shouldGetPageLength) {
            setPageLength(pageLength); // Update pageLength only when shouldGetPageLength is true
          }
          return {
            id: `row_${index}`,
            username: item.userId.username,
            feedbacktype: item.feedbackid.option,
            postedat: `${formattedDate}`,
            feedback: item.feedback,
            feedbackimage: item.imgurl,
            status: item.status,
          };
        } else if (report == "ticket") {
          const resultdatetime = new Date(item.lotteryid.resultdatetime);
          const purchaseDate = new Date(item.buydatetime);

          const enddateOptions = {
            timeZone: "UTC",
            day: "2-digit",
            month: "numeric",
            year: "numeric",
          };

          const formattedEndDate = resultdatetime.toLocaleDateString(
            "en-GB",
            enddateOptions
          );

          const purchasedateOptions = {
            timeZone: "UTC",
            day: "2-digit",
            month: "numeric",
            year: "numeric",
          };

          const formattedPurchaseDate = purchaseDate.toLocaleDateString(
            "en-GB",
            purchasedateOptions
          );
          console.log("formattedStartDate", formattedEndDate);
          let pageLength = responseData.data.pageLength;
          if (shouldGetPageLength) {
            setPageLength(pageLength); // Update pageLength only when shouldGetPageLength is true
          }
          return {
            id: `row_${index}`,
            name: item.lotteryid.name,
            ticketno: item.ticketno,
            buyername: item.buyername,
            buydatetime: formattedPurchaseDate,
            resultdatetime: formattedEndDate,
            cost: item.cost,
          };
        }
      });
      return salesList;
    } catch (error) {
      console.error("Error submitting data:", error.message);
    }
  };
  const InitiateServerFilter = () => {
    setInitiate((prev) => !prev);
    setShouldGetPageLength(true);
    setPaginationModel({
      page: 0,
      pageSize: 5,
    });
    setOpen(false);
  };
  const ClearFilter = () => {
    var newset = [{ condition: "", value: "" }];
    if (focus == "date" || focus == "doj") {
      newset = [{ start: "", end: "" }];
    }
    setFilterval((prev) => ({ ...prev, [focus]: newset }));
    setOpen(false);
    setShouldGetPageLength(true);
    setPaginationModel({
      page: 0,
      pageSize: 5,
    });
    setInitiate((prev) => !prev);
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const newRows = await fetchSaleReport(
        paginationModel.page,
        false,
        salesList
      );
      console.log("newRows", newRows);
      setSalesList(newRows);
      setShouldGetPageLength(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      // alert("asdas")
      console.log({ report, state: reportState[report] });
      fetchData();
    }
  }, [authToken, initiate, report, paginationModel]);

  function handleStartDateChange(date, index, field) {
    setFilterval((prev) => {
      var newDate = prev.date.map((dt, d) => {
        var s = dt;
        if (d == index) {
          s = { ...dt, [field]: date };
        }
        return s;
      });

      return { ...prev, date: newDate };
    });
  }

  const exportTable = async (mode) => {
    console.log({ salesList });
    //return;
    const columns = {
      sales: [
        { title: "Date", dataKey: "date" },
        { title: "No of Contest", dataKey: "TotalDraws" },
        { title: "No of Tickets", dataKey: "TotalTicketSold" },
        { title: "Total Revenue", dataKey: "TotalRevenue" },
        { title: "Prize Amount", dataKey: "TotallotteryPrize" },
        { title: "Net Amount", dataKey: "NetAmount" },
      ],
      customer: [
        { title: "Name", dataKey: "username" },
        { title: "Phone Number", dataKey: "mobileno" },
        { title: "Date of Join", dataKey: "doj" },
        { title: "Balance", dataKey: "balance" },
        { title: "Total Deposit", dataKey: "totalDeposit" },
        { title: "Total Withdraw", dataKey: "totalWithdraw" },
        { title: "Revenue", dataKey: "ticketrevenue" },
      ],
      lottery: [
        { title: "Lottery Name", dataKey: "name" },
        { title: "Date", dataKey: "date" },
        { title: "Province", dataKey: "province" },
        { title: "Total Tickets", dataKey: "ticketscount" },
        { title: "Ticktet Purchased", dataKey: "ticketSold" },
        { title: "Entry Fee", dataKey: "cost" },
        { title: "Total Prize", dataKey: "totalprize" },
        { title: "Revenue", dataKey: "ticketrevenue" },
      ],
      prizedistribution: [
        { title: "Lottery Name", dataKey: "name" },
        { title: "Date", dataKey: "date" },
        { title: "Number of Winners", dataKey: "winnerscount" },
        { title: "winner", dataKey: "prizedistribute" },
      ],
      finance: [
        { title: "Month", dataKey: "date" },
        { title: "No of Lotteries", dataKey: "TotalDraws" },
        { title: "Total Tickets", dataKey: "TotalTickets" },
        { title: "Ticktet Purchased", dataKey: "TotalTicketSold" },
        { title: "Total Lottery Prize", dataKey: "TotallotteryPrize" },
        { title: "Total Revenue", dataKey: "TotalRevenue" },
        { title: "Net Amount", dataKey: "NetAmount" },
      ],
      feedback: [
        { title: "Name", dataKey: "username" },
        { title: "Date & Time", dataKey: "postedat" },
        { title: "Description", dataKey: "feedback" },
        { title: "Status", dataKey: "status" },
        { title: "Feedback Type", dataKey: "feedbacktype" },
        { title: "Feedback Image", dataKey: "feedbackimage" },
      ],
      ticket: [
        { title: "Lottery Name", dataKey: "name" },
        { title: "Ticket Number", dataKey: "ticketno" },
        { title: "User Name", dataKey: "buyername" },
        { title: "Purchase Time", dataKey: "buydatetime" },
        { title: "Draw Time", dataKey: "resultdatetime" },
        { title: "Amount", dataKey: "cost" },
      ],
    };

    const reportsalesList = await fetchSaleReport(0, true);
    if (mode == "pdf") {
      var header = {};
      columns[report].map((cols) => {
        header[cols.title] = cols.dataKey;
      });
      console.log(Object.keys(header));
      const dataSet = reportsalesList.map((row) => {
        var newObje = {};
        Object.values(header).map((col) => {
          newObje[col] = row[col];
        });
        return newObje;
      });
      console.log(dataSet);

      const doc = new jsPDF();

      autoTable(doc, {
        columns: columns[report],
        body: dataSet,
        didDrawPage: (dataArg) => {
          doc.text(`${report} Report`, 10, 10);
        },
      });

      // Save the PDF
      doc.save(`${report} Report_${Date.now()}.pdf`);
    } else {
      var header = {};
      columns[report].map((cols) => {
        header[cols.title] = cols.dataKey;
      });
      var data = [];
      reportsalesList.forEach((row) => {
        var newSet = [];
        Object.values(header).map((head) => {
          var val = row[head];

          newSet.push(val);
        });
        data.push(newSet);
      });
      const csvData = [Object.keys(header), ...data];
      const csv = Papa.unparse(csvData);
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${report} Report_${Date.now()}`;
      link.click();
    }
  };

  console.log({ report, filterval, filter, ase: "sd" });
  return (
    <div className={classes.GridWrapper}>
      <DataGridDefault
        heading={`${report} Report`}
        rows={salesList}
        columns={reportColumns[report]}
        loading={loading}
        create={false}
        lists={salesList}
        report={`${report}`}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          padding: "20px",
          borderRadius: "11px",
        }}
      >
        <Box sx={modalStyle}>
          <h6 style={{ padding: "5px" }}>{value}</h6>
          {Object.keys(filter).map((field) => {
            if (field != "temp" && filter[field] && field == "date") {
              console.log(filterval[field], "jjjjj");
              return filterval[field].map((dateField, Index) => {
                console.log({ dateField });
                return (
                  <Grid className="custom-filter-body" spacing={1} container>
                    <div className={classes.popModalButton}>
                      <div>
                        <label>Start Date</label>
                        <DateFilter
                          onChange={(date) =>
                            handleStartDateChange(date, Index, "start")
                          }
                          name="startDate"
                          value={dateField.start}
                        />
                      </div>
                      <div>
                        <label> End Date</label>
                        <DateFilter
                          onChange={(date) =>
                            handleStartDateChange(date, Index, "end")
                          }
                          name="endDate"
                          value={dateField.end}
                        />
                      </div>
                    </div>
                  </Grid>
                );
              });
            } else if (field != "temp") {
              console.log({ field, data: filterval[field] });
              return filterval[field].map((conditions, Index) => {
                if (filter[field]) {
                  console.log({ conditions });
                  var searchfields = serchfiledsForReports[report];
                  var conditionsFOrFilter = {
                    "=": "Equal TO",
                    "=": "Equal TO",
                    "!=": "Not Equal TO",
                    ">": "Greater Than",
                    "<": "Lesser Than",
                    ">=": "Greater Than =",
                    "<=": "Lesser Than =",
                  };
                  if (searchfields.includes(field)) {
                    return (
                      <>
                        <Grid
                          className="custom-filter-body1"
                          style={{ padding: "10px 0" }}
                          spacing={1}
                          container
                        >
                          <Grid
                            className="custom-filter-body-search"
                            style={{ marginTop: "10px" }}
                            xs={12}
                          >
                            <ChakraProvider>
                              <Input
                                value={conditions["value"]}
                                onChange={(e) => handleChange(e, field, Index)}
                                placeholder="0.00"
                                style={{ padding: "10px" }}
                              />
                            </ChakraProvider>
                          </Grid>
                        </Grid>
                        {filterval[field].length == Index + 1 ? (
                          <Grid container style={{ justifyContent: "end" }}>
                            <MuiButton
                              variant="text"
                              onClick={() => addRuleFn(field)}
                              style={{
                                color: "#ffbf0c",
                                textTransform: "none",
                              }}
                            >
                              +Add Rule
                            </MuiButton>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Grid
                          className="custom-filter-body1"
                          style={{ padding: "10px 0" }}
                          spacing={1}
                          container
                        >
                          <Grid
                            className="custom-filter-body-search"
                            style={{ marginTop: "10px" }}
                            xs={5}
                          >
                            <ChakraProvider>
                              <Select
                                placeholder="Filter By"
                                onChange={(e) =>
                                  conditionChange(e, field, Index)
                                }
                              >
                                {Object.keys(conditionsFOrFilter).map(
                                  (cond) => {
                                    return (
                                      <option
                                        value={cond}
                                        selected={
                                          cond == conditions["condition"]
                                        }
                                      >
                                        {conditionsFOrFilter[cond]}
                                      </option>
                                    );
                                  }
                                )}
                              </Select>
                            </ChakraProvider>
                          </Grid>
                          <Grid xs={2} />
                          <Grid
                            className="custom-filter-body-search"
                            style={{ marginTop: "10px" }}
                            xs={5}
                          >
                            <ChakraProvider>
                              <Input
                                value={conditions["value"]}
                                onChange={(e) => handleChange(e, field, Index)}
                                placeholder="0.00"
                                style={{ padding: "10px" }}
                              />
                            </ChakraProvider>
                          </Grid>
                        </Grid>
                        {filterval[field].length == Index + 1 ? (
                          <Grid container style={{ justifyContent: "end" }}>
                            <MuiButton
                              variant="text"
                              onClick={() => addRuleFn(field)}
                              style={{
                                color: "#ffbf0c",
                                textTransform: "none",
                              }}
                            >
                              +Add Rule
                            </MuiButton>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  }
                }
              });
            }
          })}

          <div className={classes.popModalButton}>
            <div>
              <Button
                onClick={ClearFilter}
                customButtonWidth={classes.customToolButton}
              >
                Clear
              </Button>
            </div>

            <div>
              <Button
                onClick={InitiateServerFilter}
                customButtonWidth={classes.customToolButton}
              >
                Apply
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default SalesReport;
