/* 
Author: Sreenivassan R
*/
import React, { useEffect, useState } from "react";
import classes from "./AddWinner.module.css";
import ModalComponent from "./ModalComponent";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CustomInput from "./CustomInput";

function AlphaAddWinner({
  Hide,
  setFormData,
  dataHandler,
  boxLength,
  boxCount,
  onSaveWinner,
  initialPrizelist,
  disabled,
  setConse,
  conse,
}) {
  const [boxLengthWin, setBoxLengthWin] = useState(1);
  const [error, setError] = useState();
  const [formattedValue, setFormattedValue] = useState("");
  const [inputDisabled, setInputDisabled] = useState(false);

  /* Update conse state based on the presence of "Consolation" in prizelist */
  console.log({ boxLength });
  const [prizelist, setPrizelist] = useState(
    initialPrizelist && initialPrizelist.length > 0
      ? initialPrizelist
      : [
          {
            totalPrizeAmount: 0,
            prize: 0,
            digits: Array(+boxLength).fill(0),
            drawcount: 1,
            position: 1,
            noOfPrizes: "",
            prizeName: "",
            order: 1,
          },
        ]
  );
  useEffect(() => {
    const hasConsolation = prizelist.some((item) => item.prizeName === "C");
    setConse(hasConsolation);
  }, [prizelist]);

  /* To Increase the number of Winner Range and Prize Inputs */
  const handleIncrement = () => {
    setBoxLengthWin((prevState) => prevState + 1);
    setPrizelist((prevPrizelist) => {
      const newPrizeList = prevPrizelist ? [...prevPrizelist] : [];
      newPrizeList.push({
        totalPrizeAmount: 0,
        prize: 0,
        digits: Array(+boxLength).fill(0),
        drawcount: 1,
        position: newPrizeList.length + 1,
        noOfPrizes: "",
        prizeName: "",
        order: newPrizeList.length + 1,
      });
      console.log(prizelist.length);
      return newPrizeList;
    });
  };

  /* To Decrease the number of Winner Range and Prize Inputs */
  const handleDecrement = () => {
    setBoxLengthWin((prevState) => {
      const newValue = prevState > 1 ? prevState - 1 : 1;

      setPrizelist((prevPrizelist) => {
        if (prevPrizelist.length > 0) {
          return prevPrizelist.slice(0, -1);
        }
        return prevPrizelist;
      });

      return newValue;
    });
  };

  /* To Enter the number of Winner Range and Prize Inputs */
  const handleInputChange = (event) => {
    const { value } = event.target;
    const inputValue = parseInt(value);

    if (value === "" || isNaN(inputValue) || inputValue < 1) {
      setPrizelist([]);
      setBoxLengthWin(1);
    } else {
      const updatedWinner = [];
      for (let i = 0; i < inputValue; i++) {
        updatedWinner.push({
          totalPrizeAmount: 0,
          prize: 0,
          digits: Array(+boxLength).fill(0),
          drawcount: 1,
          position: i + 1,
          noOfPrizes: "",
          prizeName: "",
          order: i + 1,
        });
      }
      setPrizelist(updatedWinner);
      setBoxLengthWin(inputValue);
    }
  };

  /* To Handle Prize Amount and the Total Prize Money Calulation */
  const handlePrizeInput = (event, index) => {
    const { value } = event.target;
    const prizeAmt = parseFloat(value);

    setPrizelist((prevPrizelist) => {
      const updatedPrizelist = [...prevPrizelist];

      if (updatedPrizelist[index]) {
        let drawcount = updatedPrizelist[index].drawcount;
        if (drawcount === 0) {
          drawcount = 1;
        }
        const noOfPrizes = updatedPrizelist[index].noOfPrizes;

        if (!isNaN(prizeAmt)) {
          const multipliedValue = prizeAmt * noOfPrizes;
          updatedPrizelist[index] = {
            ...updatedPrizelist[index],
            prize: prizeAmt,
            totalPrizeAmount: multipliedValue,
          };
        } else {
          updatedPrizelist[index] = {
            ...updatedPrizelist[index],
            prize: 0,
            totalPrizeAmount: 0,
          };
        }
      }
      return updatedPrizelist;
    });
  };

  /* To Create commas in Amount value */
  const formatNumberWithCommas = (num) => {
    let numStr = num.toString();
    let lastThree = numStr.substring(numStr.length - 3);
    let otherNumbers = numStr.substring(0, numStr.length - 3);
    if (otherNumbers !== "") {
      lastThree = "," + lastThree;
    }
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  /* To Handle Number of Draw count and the Total Prize Money Calulation */
  const handleDrawCountInput = (event, index) => {
    const { value } = event.target;
    const drawcount = +value;
    let prize = prizelist[index].prize;
    const noOfPrizes = prizelist[index].noOfPrizes;
    if (!isNaN(prize)) {
      const multipliedValue = prize * noOfPrizes;
      const updatedPrizelist = [...prizelist];
      updatedPrizelist[index].totalPrizeAmount = multipliedValue;
      updatedPrizelist[index].prize = prize;
      setPrizelist(updatedPrizelist);
    }
    setPrizelist((prevPrizelist) => {
      const updatedPrizelist = [...prevPrizelist];
      updatedPrizelist[index] = {
        ...updatedPrizelist[index],
        drawcount: drawcount,
      };
      return updatedPrizelist;
    });
  };

  /* To Get the name of the Prize */
  const handlePrizeNameChange = (event, index) => {
    const { value } = event.target;
    setPrizelist((prevPrizelist) => {
      const updatedPrizelist = [...prevPrizelist];
      if (updatedPrizelist[index]) {
        updatedPrizelist[index] = {
          ...updatedPrizelist[index],
          prizeName: value,
        };
      }
      return updatedPrizelist;
    });
  };

  /* To Delete the Winner range of that index */
  const deleteItem = (id, boxLengthWin) => {
    const updatedPrizelist = prizelist.filter((item, index) => index !== id);
    const newBoxLengthWin = updatedPrizelist.length;

    setBoxLengthWin(newBoxLengthWin);
    setPrizelist(updatedPrizelist);
  };

  /*  Allow only numbers (0-9) */
  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  /* Total Prize Caluclation on Number of Winner entered */
  const onWinnerCountChange = (event, index) => {
    const { value } = event.target;
    setPrizelist((prevPrizelist) => {
      const updatedPrizelist = [...prevPrizelist];
      if (updatedPrizelist[index]) {
        const prize = updatedPrizelist[index].prize;
        const noOfPrizes = value === "" ? 0 : parseInt(value, 10); // Handle empty input by setting it to 0
        let wholePrice = prize * noOfPrizes;
        console.log({ prize });
        if (!isNaN(prize) && !isNaN(noOfPrizes)) {
          updatedPrizelist[index] = {
            ...updatedPrizelist[index],
            noOfPrizes,
            totalPrizeAmount: prize * noOfPrizes,
          };
          let inputValue = wholePrice;
          console.log({ inputValue });
          const formatted = formatNumberWithCommas(inputValue);
          console.log({ formatted });
          setFormattedValue(formatted);
        } else {
          updatedPrizelist[index] = {
            ...updatedPrizelist[index],
            noOfPrizes,
          };
        }
      }
      return updatedPrizelist;
    });
  };

  /* To Get the value of Checkbox indexes checked or not checked */
  const handleCheckboxChange = (event, index, digitIndex) => {
    setPrizelist((prevPrizelist) => {
      const updatedPrizelist = [...prevPrizelist];
      if (updatedPrizelist[index]) {
        const prevcheck = updatedPrizelist[index]["digits"][digitIndex];

        // Toggle the digit value
        const updatedDigits = [...updatedPrizelist[index]["digits"]];
        updatedDigits[digitIndex] = prevcheck === 1 ? 0 : 1;

        // Update the prize's digits array
        updatedPrizelist[index] = {
          ...updatedPrizelist[index],
          digits: updatedDigits,
        };
      }
      return updatedPrizelist;
    });
  };

  console.log({ prizelist });


  /* To Clear the values entered in the input value */
  const resetWinners = () => {
    setBoxLengthWin(prizelist.length);
    console.log(prizelist.length);

    const updatedPrizelist = prizelist.map((prize) => ({
      ...prize,
      totalPrizeAmount: 0,
      prize: 0,
      digits: Array(+boxLength).fill(0),
      drawcount: 1,
      noOfPrizes: "",
      prizeName: "",
      position: "",
    }));

    setPrizelist(updatedPrizelist);
  };

  /* To Get the Input values entered and get it to onSaveWinner */
  const winnerSave = (event) => {
    let consolationFound = false;
    let lastNumericPosition = 0;

    const updatedPrizelist = prizelist.map((item) => {
      const noOfPrizesString = `-${item.noOfPrizes}`;
      const position = item.order;
      let positionString = position.toString(); // Convert position to string

      // If prizeName is "Consolation", set the position to "Consolation"
      if (item.prizeName === "C") {
        consolationFound = true;
        lastNumericPosition = position - 1;
        positionString = "C";
      } else if (consolationFound) {
        // Adjust the position if after a "Consolation"
        lastNumericPosition += 1;
        positionString = lastNumericPosition.toString();
      }

      const finalPosition =
        item.prizeName === "C"
          ? `Consolation${noOfPrizesString}`
          : `${positionString}${noOfPrizesString}`;

      const { noOfDraw, description, name, ...newItem } = item;

      return {
        ...newItem,
        position: finalPosition,
      };
    });

    // Validate if all fields are filled

    const isValid = updatedPrizelist.every((item) => {
      console.log(item.digits); // Log the digits array for debugging purposes

      return (
        item.prizeName.trim() !== "" &&
        item.noOfPrizes !== "" &&
        item.digits.length > 0 &&
        !isNaN(item.prize) &&
        item.prize > 0 &&
        item.digits.some((digit) => digit === 1) // Ensure at least one checkbox is checked
      );
    });

    if (!isValid) {
      if (!isValid) {
        setError("Please fill out all required fields correctly.");
      }
      return;
    }

    console.log({ updatedPrizelist });
    onSaveWinner(updatedPrizelist);
  };

  /* To Create a winnerRange and PrizeList on Consolation Checkbox Click */
  const handleConsolation = (event, id) => {
    const isChecked = event.target.checked;
    setConse(isChecked);
    if (isChecked) {
      // If checkbox is checked
      setBoxLengthWin((prevState) => prevState + 1);

      setPrizelist((prevPrizelist) => {
        const newPrizeList = prevPrizelist ? [...prevPrizelist] : [];
        newPrizeList.push({
          totalPrizeAmount: 0,
          prize: 0,
          digits: Array(+boxLength).fill(0),
          drawcount: 0,
          position: newPrizeList.length + 1,
          noOfPrizes: "",
          prizeName: "C",
          order: newPrizeList.length + 1,
        });
        console.log(newPrizeList.length);
        const indexToRemove = newPrizeList.findIndex(
          (prize) => prize.prizeName === "C"
        );
        if (indexToRemove !== -1) {
          setInputDisabled(true);
        }

        return newPrizeList;
      });
    } else {
      // If checkbox is unchecked
      setBoxLengthWin((prevState) => (prevState > 0 ? prevState - 1 : 0));

      setPrizelist((prevPrizelist) => {
        if (!prevPrizelist) return prevPrizelist;

        const newPrizeList = [...prevPrizelist];
        const indexToRemove = newPrizeList.findIndex(
          (prize) => prize.prizeName === "C"
        );

        if (indexToRemove !== -1) {
          newPrizeList.splice(indexToRemove, 1);
        }
        return newPrizeList;
      });
    }
  };

  return (
    <ModalComponent Hide={Hide} modalComponent={classes.modalWidth}>
      <div className={classes.winnerFlex}>
        <div className={classes.popTitle}>
          <h4>Winner Range and Prize</h4>
        </div>
        <div className={classes.felxSpace}>
          <div className={classes.winnerFlex}>
            {error && <p>{error}</p>}
            <div className={classes.winnerRangePrize}>
              {!disabled && (
                <div className={classes.boxButton}>
                  <button type="button" onClick={handleDecrement}>
                    -
                  </button>
                </div>
              )}
              <div>
                <input
                  type="number"
                  palceholder="0"
                  value={prizelist.length}
                  onChange={handleInputChange}
                  disabled={disabled}
                  onKeyPress={handleKeyPress}
                />
              </div>
              {!disabled && (
                <div className={classes.boxButton}>
                  <button type="button" onClick={handleIncrement}>
                    +
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className={classes.checkedBox}>
            <input
              type="checkbox"
              name="consolation"
              onChange={handleConsolation}
              checked={conse}
              disabled={disabled}
            />
            <p>Consolation</p>
          </div>
        </div>
      </div>

      <div className={classes.lottWinnerCount}>
        {prizelist.length
          ? prizelist.map((prizedata, index) => (
              <div
                className={`${classes.alphaAddWinnerFlex} ${classes.alphaAddWinnerFlexMargin}`}
                key={index}
              >
                <div className={classes.boxLengthFlex}>
                  <div className={classes.checkCenter}>
                    <label>Numbers</label>
                    <div className={classes.boxLengthFlex}>
                      {Array.from({ length: boxCount.length }).map(
                        (_, digitIndex) => {
                          return (
                            <div
                              key={digitIndex}
                              className={classes.checkBoxWrap}
                            >
                              <div className={classes.ticketUnit}>
                                {boxCount[digitIndex]?.initial}
                              </div>
                              <input
                                type="checkbox"
                                checked={prizedata.digits[digitIndex] === 1}
                                onChange={(event) =>
                                  handleCheckboxChange(event, index, digitIndex)
                                }
                                disabled={disabled}
                                className={classes.winCheckbox}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className={classes.alphawinnerRange}>
                    <CustomInput
                      label={"Winner Count"}
                      value={prizedata.noOfPrizes}
                      placeholder="0"
                      customInput={classes.customInputWinner}
                      disabled={disabled}
                      onChange={(event) => onWinnerCountChange(event, index)}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                </div>

                <div className={classes.alphaAddWinnerFlex}>
                  <CustomInput
                    label="Draws"
                    type="number"
                    name="drawcount"
                    value={prizedata.drawcount}
                    onChange={(event) => handleDrawCountInput(event, index)}
                    onKeyPress={handleKeyPress}
                    disabled={prizedata.prizeName === "C" ? true : disabled}
                  />
                  <CustomInput
                    label="Prize Name"
                    type="text"
                    placeholder="Prize Name"
                    name="prizeName"
                    value={prizedata.prizeName}
                    onChange={(event) => handlePrizeNameChange(event, index)}
                    disabled={prizedata.prizeName === "C" ? true : disabled}
                  />
                  <CustomInput
                    label="Prize Amount"
                    type="number"
                    placeholder="00.00"
                    name={{ input: "totalprize" }}
                    dataHandler={dataHandler}
                    value={prizedata.prize}
                    onChange={(event) => handlePrizeInput(event, index)}
                    setFormData={setFormData}
                    prizelist={prizelist}
                    prizeIndex={index}
                    lotteryDropDownWidth={classes.lotteryDropDownWidth}
                    disabled={disabled}
                    comma={true}
                    onKeyPress={handleKeyPress}
                  />
                  <CustomInput
                    label="Total Amount"
                    type="number"
                    placeholder="00.00"
                    value={
                      (prizedata.totalPrizeAmount &&
                        prizedata.totalPrizeAmount) ||
                      0
                    }
                    lotteryDropDownWidth={classes.lotteryDropDownWidth}
                    disabled
                    comma={true}
                  />
                  {!disabled && (
                    <button
                      onClick={() => deleteItem(index)}
                      className={classes.deleteTrash}
                    >
                      <FontAwesomeIcon icon={faTrashCan} color="red" />
                    </button>
                  )}
                </div>
              </div>
            ))
          : ""}
      </div>
      {!disabled && (
        <div className={classes.functionButton}>
          <Button
            customButton={classes.resetButton}
            customButtonWidth={classes.button}
            onClick={resetWinners}
          >
            Reset
          </Button>
          <Button customButtonWidth={classes.button} onClick={winnerSave}>
            Save
          </Button>
        </div>
      )}
    </ModalComponent>
  );
}

export default AlphaAddWinner;
