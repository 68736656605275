/* 
Author: Sreenivassan R
Module: Profile
*/
import React, { Fragment, useEffect, useState } from "react";
import classes from "./Profile.module.css";
import porfileBg from "../../assetes/images/profileBg.png";
import editIcon from "../../assetes/images/profileEditPic.png";
import { fetchProfileInfo } from "../../util/profile";
import { useNavigate } from "react-router-dom";

function Profile() {
  const [profile, setProfile] = useState([]);
  const [profileImage, setProfileImage] = useState();
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!authToken) {
        navigate("/");
      } else {
        try {
          fetchProfileInfo(authToken, setProfile, profile);
          setProfileImage();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();

  }, [authToken]);

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      setProfileImage(base64String);

      console.log(profileImage);
    };
    reader.readAsDataURL(selectedImage);
  };

  return (
    <Fragment>
      <div className={classes.ProfileWrapper}>
        {profile.map((data) => (
          <div key={data.username}>
            <div className={classes.profileBackground}>
              <h4>Profile</h4>
              <img src={porfileBg} alt="Profile Background" />
              <div className={classes.profileBgFlex}>
                <div className={classes.editSection}>
                  <div className={classes.editSectionFlex}>
                    <div className={classes.profileEditPic}>
                      <input
                        type="file"
                        className={classes.profileEditIcon}
                        onChange={handleImageChange}
                      />
            
                 
                      {!profileImage && data.imgurl && (
                        <img
                          src={data.imgurl}
                          className={classes.UserprofieImage}
                          alt="Profile"
                        />
                      )}
                    </div>

                    <div>
                      <p>{data.username}</p>
                      <p>{data.email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.profileInfo}>
              <div className={classes.profielBox}>
                <h4>Profile Information</h4>
                <div className={classes.profileBoxFlex}>
                  <div className={classes.infoFlexOne}>
                    <div className={classes.infoFlex}>
                      <div className={classes.flexSpan}>
                        <div className={classes.profileTitle}>
                          <p>Full Name</p>
                        </div>
                        <span>:</span>
                      </div>
                      <div className={classes.profileInfoDetail}>
                        <p>{data.username}</p>
                      </div>
                    </div>
                    <div className={classes.infoFlex}>
                      <div className={classes.flexSpan}>
                        <div className={classes.profileTitle}>
                          <p>Date of Birth</p>
                        </div>
                        <span>:</span>
                      </div>
                      <div className={classes.profileInfoDetail}>
                        <p>{data.DOB}</p>
                      </div>
                    </div>
                    <div className={classes.infoFlex}>
                      <div className={classes.flexSpan}>
                        <div className={classes.profileTitle}>
                          <p>Gender</p>
                        </div>
                        <span>:</span>
                      </div>
                      <div className={classes.profileInfoDetail}>
                        <p className={classes.genderInfo}>{data.gender}</p>
                      </div>
                    </div>
                    <div className={classes.infoFlex}>
                      <div className={classes.flexSpan}>
                        <div className={classes.profileTitle}>
                          <p>Employee ID</p>
                        </div>
                        <span>:</span>
                      </div>
                      <div className={classes.profileInfoDetail}>
                        <p>{data.empId}</p>
                      </div>
                    </div>
                    <div className={classes.infoFlex}>
                      <div className={classes.flexSpan}>
                        <div className={classes.profileTitle}>
                          <p>Nationality</p>
                        </div>
                        <span>:</span>
                      </div>
                      <div className={classes.profileInfoDetail}>
                        <p>{data.nationality}</p>
                      </div>
                    </div>
                    <div className={classes.infoFlex}>
                      <div className={classes.flexSpan}>
                        <div className={classes.profileTitle}>
                          <p>Aadhaar Number</p>
                        </div>
                        <span>:</span>
                      </div>
                      <div className={classes.profileInfoDetail}>
                        <p>{data.adhaarno}</p>
                      </div>
                    </div>
                  </div>
                  <div className={classes.infoFlexOne}>
                    <div className={classes.infoFlex}>
                      <div className={classes.flexSpan}>
                        <div className={classes.profileTitle}>
                          <p>Email</p>
                        </div>
                        <span>:</span>
                      </div>
                      <div className={classes.profileInfoDetail}>
                        <p>{data.email}</p>
                      </div>
                    </div>
                    <div className={classes.infoFlex}>
                      <div className={classes.flexSpan}>
                        <div className={classes.profileTitle}>
                          <p>Mobile Number</p>
                        </div>
                        <span>:</span>
                      </div>
                      <div className={classes.profileInfoDetail}>
                        <p>{data.mobileno}</p>
                      </div>
                    </div>
                    <div className={classes.infoFlex}>
                      <div className={classes.flexSpan}>
                        <div className={classes.profileTitle}>
                          <p>Emergency Contact Name</p>
                        </div>
                        <span>:</span>
                      </div>
                      <div className={classes.profileInfoDetail}>
                        <p>{data.emgcontactname}</p>
                      </div>
                    </div>
                    <div className={classes.infoFlex}>
                      <div className={classes.flexSpan}>
                        <div className={classes.profileTitle}>
                          <p>Emergency Number</p>
                        </div>
                        <span>:</span>
                      </div>
                      <div className={classes.profileInfoDetail}>
                        <p>{data.emgcontactnumber}</p>
                      </div>
                    </div>
                  </div>
                  <div className={classes.infoFlexOne}>
                    <div className={classes.infoFlex}>
                      <div className={classes.flexSpan}>
                        <div className={classes.profileTitle}>
                          <p>Address</p>
                        </div>
                        <span>:</span>
                      </div>
                      <div className={classes.profileInfoDetail}>
                        <p>{data.address}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.profileFlex}>
                <div className={classes.profielBox}>
                  <h4>Roles</h4>
                  <p>{data.role}</p>
                </div>
                <div className={classes.profielBox}>
                  <h4>Premissions</h4>
                  <div className={classes.infoFlex}>
                    <div className={classes.flexSpan}>
                      <div className={classes.profileTitle}>
                        <p>View</p>
                      </div>
                      <span>:</span>
                    </div>
                    <div className={classes.profileInfoDetail}>
                      <p>
                        {data.filteredModules.view.includes("All")
                          ? "All"
                          : data.filteredModules.view
                              .map((item) => item.split(/(?=[A-Z])/).join(" "))
                              .join(", ")}
                      </p>
                    </div>
                  </div>
                  <div className={classes.infoFlex}>
                    <div className={classes.flexSpan}>
                      <div className={classes.profileTitle}>
                        <p>Edit</p>
                      </div>
                      <span>:</span>
                    </div>
                    <div className={classes.profileInfoDetail}>
                      <p>
                      {data.filteredModules.edit.includes("All")
                          ? "All"
                          : data.filteredModules.edit
                              .map((item) => item.split(/(?=[A-Z])/).join(" "))
                              .join(", ")}

                      </p>
                    </div>
                  </div>
                  <div className={classes.infoFlex}>
                    <div className={classes.flexSpan}>
                      <div className={classes.profileTitle}>
                        <p>Delete</p>
                      </div>
                      <span>:</span>
                    </div>
                    <div className={classes.profileInfoDetail}>
                      <p>
                        {data.filteredModules.delete
                          .map((item) => item.split(/(?=[A-Z])/).join(" "))
                          .join(", ")}
                      </p>
                    </div>
                  </div>
                  <div className={classes.infoFlex}>
                    <div className={classes.flexSpan}>
                      <div className={classes.profileTitle}>
                        <p>Add</p>
                      </div>
                      <span>:</span>
                    </div>
                    <div className={classes.profileInfoDetail}>
                      <p>
                        {data.filteredModules.add
                          .map((item) => item.split(/(?=[A-Z])/).join(" "))
                          .join(", ")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
}

export default Profile;
