/* 
Author: Sreenivassan R
*/
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridPagination,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import classes from "./DataGridSetup.module.css";
import CustomToolDefault from "./CustomToolDefault";
function DataGridDefault({
  heading,
  rows,
  columns,
  handleRowClick,
  loading,
  sortField,
  permission,
  createFunction,
  lists,
  report,
}) {
  const [sortModel, setSortModel] = useState([
    { field: sortField, sort: "asc" },
  ]);
  useEffect(() => {
    setSortModel([{ field: sortField, sort: "asc" }]);
  }, [sortField]);
  return (
    <div className={classes.GridWrapper}>
      <h4>{heading}</h4>
      <DataGrid
        slots={{
          toolbar: () => (
            <CustomToolDefault
              permission={permission}
              createFunction={createFunction}
              lists={lists}
              report={report}
            />
          ),
          Pagination: GridPagination,
          QuickFilter: GridToolbarQuickFilter,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        sx={{
          "& .MuiTypography-root": {
            fontFamily: "Poppins, sans-serif !important",
          },
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            fontFamily: "Poppins, sans-serif !important",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-toolbarContainer": {
            flexDirection: "row-reverse",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButtonBase-root": {
            backgroundColor: "#ffbf0c",
            color: "white",
            padding: "8px 1rem",
            width: "100px",
            fontSize: "12px",
            fontFamily: "Poppins, sans-serif",
            textTransform: "capitalize",
          },
          "& .MuiDataGrid-panel": {
            transform: "translate3d(1357px, 254px, 0px) !important",
          },
          "& .MuiBadge-badge": {
            backgroundColor: "white",
            color: "#ffbf0c",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
          },
          "& .MuiButton-startIcon": {
            display: "none",
          },
          "& .MuiDataGrid-overlayWrapper": {
            height: "4rem !important",
          },
          "& .actions": {
            display: "grid",
            gridTemplateColumns: "1fr",
            gridGap: "1rem",
          },
          "& .MuiDataGrid-main": {
            height: "70vh",
          },
          "& .MuiFormControl-root": {
            height: "45px",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            fontFamily: "Poppins, sans-serif !important",
            paddingLeft: "0.4rem",
            color: "#8E92BC",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-actionsCell": {
            justifyContent: "normal",
          },
          "& .MuiDataGrid-cell": {
            paddingLeft: "1rem",
          },
          "& .MuiToolbar-regular": {
            paddingLeft: "1rem",
            paddingRight: "18px",
          },
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            overflow: "visible",
            fontSize: "12px",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "14px",
          },
          "& .MuiButtonBase-root": {
            padding: "2px",
          },
        }}
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        loading={loading}
        disableRowSelectionOnClick /* Disables row selection when clicking on a row to prevent unintended selection, ensuring the focus remains on other row actions. */
        disableColumnSelector /* Disables the option for users to toggle column visibility, maintaining a consistent column layout. */
        disableDensitySelector /* Disables the density selector which controls the row height, keeping a uniform density across all rows. */
        disableColumnFilter /* Disables the column filtering options to simplify the interface, especially when filtering isn't necessary. */
        getRowClassName={(params) =>
          `super-app-theme--${params.row.status}`
        } /* Dynamically assigns a CSS class to each row based on its status, allowing conditional styling. */
        pageSizeOptions={[
          10, 25, 50, 100,
        ]} /* Provides a set of options for the number of rows per page, allowing users to choose how much data to display at once. */
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }} /* Sets the initial page size to 10 rows per page, giving a default view for users upon first load.*/
        sortModel={
          sortModel
        } /* Specifies the current sort state, controlling the default sort order of the rows.*/
        onSortModelChange={(model) =>
          setSortModel(model)
        } /* Updates the sort model state when the user changes the sorting order, allowing for dynamic sorting.*/
        disableColumnMenu /* Disables the column menu options like sorting, filtering, and column hiding, for a cleaner UI without advanced features.*/
      />
    </div>
  );
}

export default DataGridDefault;
