/* 
Author: Sreenivassan R
Module: Create FAQ
*/
import React, { useEffect, useState } from "react";
import ModalComponent from "../UI/ModalComponent";
import CustomInput from "../UI/CustomInput";
import classes from "../CreateModal.module.css";
import { useNavigate } from "react-router-dom";
import { createFaqData } from "../../util/faq";
import Button from "../UI/Button";
import { toast } from "react-toastify";
import TextDescription from "../UI/TextDescription";

function FaqCreateModal({
  Hide,
  updateFaqDataGet,
  id,
  notifysuccess,
  notifyfail,
  setRender,
}) {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [faqData, setFaqData] = useState({
    answer: "",
    question: "",
  });

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      if (id) {
        console.log(id);
        setFaqData(updateFaqDataGet);

        console.log(updateFaqDataGet);
      }
    }
  }, [updateFaqDataGet]);

  /* Getting data from the FAQ Input */
  const dataHandler = (e) => {
    const { name, value } = e.target;

    setFaqData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function faqHandler() {
    createFaqData(
      authToken,
      setFaqData,
      setIsSubmitting,
      id,
      faqData,
      validateForm,
      setValidationErrors,
      toast,
      notifysuccess,
      notifyfail,
      Hide,
    );
  }

  const validateForm = (faqData) => {
    const errors = {};

    errors.question = !faqData.question ? "Please enter Banner Name" : "";
    errors.answer = !faqData.answer ? "Please enter a Description" : "";

    return errors;
  };

  /* Getting Description Input */
  const handleContentChange = (event) => {
    /*  Replace all "/" characters with "\/" */
    const updatedContent = event.target.value;

    /* Update the state with the modified content */
    setFaqData((prevState) => ({
      ...prevState,
      answer: updatedContent,
    }));
  };

  const title = id ? "Edit" : "Create";

  return (
    <ModalComponent Hide={Hide}>
      <h4 className={classes.h4Editor}>{title} FAQ</h4>
      <CustomInput
        label="Heading"
        type="text"
        placeholder="Heading"
        name="question"
        value={faqData.question}
        customInput={
          validationErrors.question ? classes.errorInput : classes.inputBorder
        }
        onChange={dataHandler}
        autoComplete="off"
        validationErrors={validationErrors.question}
      />
      <div className={classes.editorStyle}>
        <TextDescription
          lable="Description"
          name="answer"
          onChange={handleContentChange}
          value={faqData.answer}
        />
      </div>

      <div className={classes.buttonWrap}>
        <Button
          type="button"
          customButtonWidth={classes.button}
          disabled={isSubmitting}
          onClick={faqHandler}
        >
          {isSubmitting ? "Submitting..." : "Save"}
        </Button>
      </div>
    </ModalComponent>
  );
}

export default FaqCreateModal;
