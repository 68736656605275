/* 
Author: Sreenivassan R
Module: Create Privacy Policy
*/
import React, { useEffect, useState } from "react";
import ModalComponent from "../UI/ModalComponent";
import classes from "../CreateModal.module.css";
import { useNavigate } from "react-router-dom";
import QuillEditor from "../UI/QuillEditor";
import Button from "../UI/Button";
import { toast } from "react-toastify";
import { createTermsData } from "../../util/policy";
import TextDescription from "../UI/TextDescription";

function PrivacyCreateModal({
  Hide,
  updatePrivacyDataGet,
  id,
  notifysuccess,
  notifyfail,
  setRender,
}) {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [howToPlayData, sethowToPlayData] = useState({
    html: "",
  });

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      if (id) {
        console.log(id);
        sethowToPlayData(updatePrivacyDataGet);
        console.log(updatePrivacyDataGet);
      }
    }
  }, [updatePrivacyDataGet]);

  function howtoplayHandler() {
    createTermsData(
      authToken,
      sethowToPlayData,
      setIsSubmitting,
      id,
      howToPlayData,
      validateForm,
      setValidationErrors,
      toast,
      notifysuccess,
      Hide,
      setRender
    );
  }

  const validateForm = (howToPlayData) => {
    const errors = {};
    errors.html = !howToPlayData.html ? "Please enter a Description" : "";

    return errors;
  };

  /* Getting Description Input */
  const handleContentChange = (event) => {
    /*  Replace all "/" characters with "\/" */
    const updatedContent = event.target.value;

    /* Update the state with the modified content */
    sethowToPlayData((prevState) => ({
      ...prevState,
      html: updatedContent,
    }));
  };

  const title = id ? "Edit" : "Create";

  return (
    <ModalComponent Hide={Hide}>
      <h4 className={classes.h4Editor}>{title} Privacy and Policy</h4>

      <div className={classes.editorStyle}>
        <TextDescription
          lable="Description"
          name="html"
          onChange={handleContentChange}
          value={howToPlayData.html}
        />
      </div>
      <div className={classes.buttonWrap}>
        <Button
          type="button"
          customButtonWidth={classes.button}
          disabled={isSubmitting}
          onClick={howtoplayHandler}
        >
          {isSubmitting ? "Submitting..." : "Save"}
        </Button>
      </div>
    </ModalComponent>
  );
}

export default PrivacyCreateModal;
