/* 
Author: Sreenivassan R
*/
import React, { useState, useEffect } from "react";
import { Toolbar, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import classes from "./CustomToolDataGrid.module.css";
import Button from "./Button";
import DateFilter from "../DateFilter";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";

const CustomToolDataGrid = ({
  permission,
  handleStartDateChange,
  handleEndDateChange,
  formateStartDate,
  formateEndDate,
  startdate,
  enddate,
  createFunction,
  date,
  handleSearch,
  searchData,
  handleClear,
  value,
  searchValueRef,
  fetchData,
  report,
  exportBtn
}) => {
  const [currentColor, setCurrentColor] = useState("#43474b");
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  function handleHoverChange() {
    setCurrentColor("#f44336");
  }
  function handleLeaveChange() {
    setCurrentColor("#43474b");
  }
  console.log({ fetchData });
  useEffect(() => {
    if (searchValueRef) {
      searchValueRef.current.focus();
    }
  }, [value]);

  const parseDate = (dateString) => {
    return new Date(dateString);
  };
  const formattedStartDate = startdate ? parseDate(startdate) : null;
  const formattedEndDate = enddate ? parseDate(enddate) : null;

  /* To get for the CSV and PDV print */
  const exportTable = async (mode) => {
    const columns = {
      lottery: [
        { title: "Lottery Name", dataKey: "name" },
        { title: "Category", dataKey: "lotterycategoryname" },
        { title: "Date", dataKey: "ticketstartdatetime" },
        { title: "Total Prize", dataKey: "totalprize" },
        { title: "Ticket Price", dataKey: "cost" },
      ],
      result: [
        { title: "Name", dataKey: "name" },
        { title: "Category", dataKey: "lotterycategoryname" },
        { title: "Date", dataKey: "ticketstartdatetime" },
        { title: "Prize Amount", dataKey: "totalprize" },
        { title: "Ticket Price", dataKey: "cost" },
      ],
      admin: [
        { dataKey: "username", title: "Admin Name" },
        { dataKey: "mobileno", title: "Mobile No" },
        { dataKey: "address", title: "Address" },
        { dataKey: "adhaarno", title: "Adhaar No" },
        { dataKey: "empId", title: "Employee Id" },
        { dataKey: "role", title: "User Name" },
        { dataKey: "logmode", title: "Log Mode" },
        { dataKey: "username", title: "User" },
      ],
    };

    const reportsalesList = fetchData;
    console.log({ reportsalesList });
    if (mode == "pdf") {
      var header = {};
      columns[report].forEach((cols) => {
        header[cols.title] = cols.dataKey;
      });
      console.log(Object.keys(header));
      const dataSet = reportsalesList.map((row) => {
        var newObje = {};
        Object.values(header).forEach((col) => {
          newObje[col] = row[col];
        });
        return newObje;
      });
      console.log(dataSet);

      const doc = new jsPDF();

      autoTable(doc, {
        columns: columns[report],
        body: dataSet,
        didDrawPage: (dataArg) => {
          doc.text(`${report} Report`, 10, 10);
        },
      });

      /* Save the PDF */
      doc.save(`${report} Report_${Date.now()}.pdf`);
    } else {
      var header = {};
      columns[report].forEach((cols) => {
        header[cols.title] = cols.dataKey;
      });
      var data = [];
      reportsalesList.forEach((row) => {
        var newSet = [];
        Object.values(header).forEach((head) => {
          var val = row[head];
          newSet.push(val);
        });
        data.push(newSet);
      });
      const csvData = [Object.keys(header), ...data];
      const csv = Papa.unparse(csvData);
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${report} Report_${Date.now()}.csv`;
      link.click();
    }
  };

  return (
    <Toolbar className={classes.toolPadding}>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        <div className={classes.customFlex}>
          <div className={classes.searchFilter}>
            <div>
              <label>Search</label>
              <input
                name="search"
                type="text"
                onChange={handleSearch}
                autoComplete="off"
                value={value}
                ref={searchValueRef}
              />
            </div>
            {searchData && (
              <span className={classes.buttonSearch}>
                <button
                  type="button"
                  onMouseOver={handleHoverChange}
                  onMouseLeave={handleLeaveChange}
                  onClick={handleClear}
                >
                  <svg
                    width="0.7em"
                    height="0.7em"
                    viewBox="0 0 16 16"
                    fill={currentColor}
                    aria-hidden="true"
                    focusable="false"
                    aria-label="close"
                    data-category="application"
                  >
                    <path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z"></path>
                  </svg>
                </button>
              </span>
            )}
          </div>

          <div className={classes.buttonFlex}>
            {date && (
              <div className={classes.buttonFlex}>
                <DateFilter
                  label="Start Date"
                  onChange={handleStartDateChange}
                  name="startDate"
                  value={formattedStartDate}
                  placeholder="DD/MM/YYYY"
                />
                <DateFilter
                  label="Result Date"
                  onChange={handleEndDateChange}
                  name="endDate"
                  value={formattedEndDate}
                  placeholder="DD/MM/YYYY"
                />
              </div>
            )}
            {exportBtn && (
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <div className={classes.buttonFlex}>
                  <Button
                    id="basic-button"
                    className="datagrid-export-button"
                    aria-controls={open1 ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={handleClick}
                    customButton="ss"
                    customButtonWidth={classes.exportButton}
                  >
                    Export
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open1}
                    onClose={handleClose1}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => exportTable("pdf")}>PDF</MenuItem>
                    <MenuItem onClick={() => exportTable("csv")}>CSV</MenuItem>
                  </Menu>
                </div>
              </Typography>
            )}

            {permission && permission.add && (
              <Button
                customButtonWidth={classes.customToolButton}
                onClick={createFunction}
              >
                Create
              </Button>
            )}
          </div>
        </div>
      </Typography>
    </Toolbar>
  );
};

export default CustomToolDataGrid;
