/* 
Author: Sreenivassan R
*/
import { toast } from "react-toastify";
import { generateToken } from "./tokenGenerate";
const ADMINURL = process.env.REACT_APP_ADMINURL;

// Lottery Data
export const fetchLotteryData = async (
  setLotteryData,
  authToken,
  shouldGetPageLength,
  page,
  setPageLength,
  startDate,
  endDate,
  pageLimit,
  searchValue,
  retry = true
) => {
  try {
    const response = await fetch(ADMINURL + "/admin/getLottery", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        mode: "lottery",
        limit: pageLimit,
        page: page,
        from: startDate,
        to: endDate,
        getTotalRecords: shouldGetPageLength,
        search: searchValue,
        isPrint: false,
      }),
    });
    const responseData = await response.json();
console.log(responseData)
    if (responseData.sts === "2" && retry) {
      const genToken = await generateToken();
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await fetchLotteryData(
          setLotteryData,
          authToken,
          shouldGetPageLength,
          page,
          setPageLength,
          startDate,
          endDate,
          pageLimit,
          searchValue,
          false
        );
        return;
      }
    }
    console.log(responseData);

    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    if (!responseData.data || !responseData.data.lotterylist) {
      throw new Error("Invalid data structure: Missing lotterylist");
    }

    const lotteryData = responseData.data.lotterylist.map((item) => {
      //Start Date Time
      let pageLength = responseData.data.Totalcount;
      if (shouldGetPageLength) {
        setPageLength(pageLength); // Update pageLength only when shouldGetPageLength is true
      }
      const ticketStartTime = new Date(item.ticketstartdatetime);
      const startdateOptions = {
        timeZone: "Asia/Kolkata",
        month: "short",
        day: "2-digit",
        year: "numeric",
      };

      const starttimeOptions = {
        timeZone: "Asia/Kolkata",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const formattedDate = ticketStartTime.toLocaleDateString(
        "en-US",
        startdateOptions
      );
      const formattedTime = ticketStartTime.toLocaleTimeString(
        "en-US",
        starttimeOptions
      );

      //Result Date Time
      const resultStartTime = new Date(item.resultdatetime);
      const resultdateOptions = {
        timeZone: "Asia/Kolkata",
        month: "short",
        day: "2-digit",
        year: "numeric",
      };

      const resulttimeOptions = {
        timeZone: "Asia/Kolkata",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const lotterycategoryname = Array.isArray(item.lotterycategoryid)
        ? item.lotterycategoryid.map((item) => item.categoryname)
        : item.lotterycategoryid && Object.keys(item.lotterycategoryid).length
        ? item.lotterycategoryid.categoryname
        : "";

      const formattedResultDate = resultStartTime.toLocaleDateString(
        "en-US",
        resultdateOptions
      );
      const formattedResultTime = resultStartTime.toLocaleTimeString(
        "en-US",
        resulttimeOptions
      );

      return {
        LottId: item.id,
        id: item._id,
        name: item.name,
        totalprize: item.totalprize,
        ticketstartdatetime: `${formattedDate}  ${formattedTime}`,
        resultdatetime: `${formattedResultDate}  ${formattedResultTime}`,
        cost: item.cost,
        lotterycategoryname: lotterycategoryname,
        lotterystatus: item.lotterystatus,
      };
    });

    console.log("resultData", lotteryData);
    setLotteryData(lotteryData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

// Lottery Catagory
export const catagoryApi = async (authToken) => {
  try {
    const response = await fetch(ADMINURL + "/admin/getlotterycategory", {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = await response.json();

    return data.data;
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
    return [];
  }
};

// Lottery Prize
export const PriceApi = async (authToken, retry = true) => {
  try {
    const response = await fetch(ADMINURL + "/admin/getPrices", {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = await response.json();

    if (data.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated Price");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await PriceApi(authToken, false);
        return;
      }
    }
    console.log(data.data);
    return data.data;
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
    return [];
  }
};

// Lottery Series
export const SeriesApi = async (authToken, retry = true) => {
  try {
    const response = await fetch(ADMINURL + "/admin/lotterySeries", {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = await response.json();

    if (data.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated Price");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await SeriesApi(authToken, false);
        return;
      }
    }
    console.log(data.data);
    return data.data;
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
    return [];
  }
};

// Lottery State
export const stateApi = async (authToken, id, retry = true) => {
  try {
    const response = await fetch(ADMINURL + `/admin/getStates?userId=${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = await response.json();
    if (data.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated State");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await stateApi(authToken, id, false);
        return;
      }
    }
    console.log(data);

    return data.data;
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
    return [];
  }
};

export const getlottery = async (lotteryEditData, authToken, retry = true) => {
  try {
    const response = await fetch(ADMINURL + "/admin/getlottery", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ lotteryId: lotteryEditData, mode: "info" }),
    });
    const data = await response.json();
    console.log(data)
    if (data.sts === "2" && retry) {
      const genToken = await generateToken();
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await getlottery(lotteryEditData, authToken, false);
        return;
      }
    }
    console.log("data", data);
    return { ...data.data.lotterylist[0] };
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
    return [];
  }
};

// Lottery Delete
export const lotteryDelete = async function handleDeleteClick(
  lotteryId,
  authToken,
  notifysuccess,
  notifyfail
) {
  console.log(lotteryId);
  const isConfirmed = window.confirm("Do You Want to Delete this Lottery?");
  if (isConfirmed) {
    try {
      const response = await fetch(ADMINURL + "/admin/lottery", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ lotteryId: lotteryId, mode: "delete" }),
      });
      const data = await response.json();
      if (data.sts === "1") {
        notifyfail(data.msg);
      } else if (data.sts === "0") {
        notifysuccess("Success");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        notifyfail("Something Went Wrong");
      }
    } catch (error) {
      notifyfail(error.message);
    }
  }
};
