/* 
Author: Sreenivassan R
*/
const ADMINURL = process.env.REACT_APP_ADMINURL;
export const fetchTermsData = async (authToken, setTermsGet, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(ADMINURL + "/admin/get-legal-info", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ type: "privacy" }), // Include the payload here
    });
    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    console.log("Policy", responseData.data);

    const termsGet = responseData.data.map((item, index) => {
      //Date Time
      const ticketStartTime = new Date(item.datetime);
      const startdateOptions = {
        timeZone: "Asia/Kolkata",
        month: "short",
        day: "2-digit",
        year: "numeric",
      };

      const starttimeOptions = {
        timeZone: "Asia/Kolkata",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const formattedDate = ticketStartTime.toLocaleDateString(
        "en-US",
        startdateOptions
      );
      const formattedTime = ticketStartTime.toLocaleTimeString(
        "en-US",
        starttimeOptions
      );
      return {
        uniqueId: `row_${index}`,
        id: item._id,
        _id: item._id,
        datetime: `${formattedDate}  ${formattedTime}`,
        html:item.html
      };
    });

    setTermsGet(termsGet);
    setLoading(false);
    console.log("faqDataGet", termsGet);
    console.log("Data submitted successfully:", termsGet);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

export const createTermsData = async function howtoplayHandler(
  authToken,
  sethowToPlayData,
  setIsSubmitting,
  id,
  howToPlayData,
  validateForm,
  setValidationErrors,
  toast,
  notifysuccess,
  Hide,
  setRender
) {
  const errors = validateForm(howToPlayData);
  console.log(errors);

  if (Object.values(errors).some((va) => va !== "")) {
    setValidationErrors(errors);
    console.log(errors);
    console.log("Form Data:", howToPlayData);
    return;
  } else {
    sethowToPlayData((prevFormData) => ({
      ...prevFormData,
    }));
  }

  const innerHTMLValue =
    typeof howToPlayData.html === "object"
      ? howToPlayData.html.__html
      : howToPlayData.html;

  try {
    setIsSubmitting(true);
    var postdata = id
      ? {
          id: id,
          mode: "update",
        }
      : {
          mode: "insert",
        };

    // Include innerHTMLValue in the body object
    const response = await fetch(ADMINURL + "/admin/legal-info", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        ...howToPlayData,
        ...postdata,
        html: innerHTMLValue,
        type: "privacy",
      }),
    });

    const responseData = await response.json();
    console.log("loteryList", responseData);
    setIsSubmitting(false);
    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    if (responseData.sts === "1") {
      const notifyfail = () =>
        toast.error(responseData.msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      notifyfail();
      return;
    }
    if (responseData.sts === "0") {
      notifysuccess();
      Hide();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    setRender((prev) => !prev);
    console.log("Data submitted successfully:", responseData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};


