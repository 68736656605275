/* 
Author: Sreenivassan R
*/
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridPagination,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import CustomToolDataGrid from "./CustomToolDataGrid";
import classes from "./DataGridSetup.module.css";

function DataGridSetup({
  heading,
  rows,
  columns,
  paginationModel,
  handlePaginationModelChange,
  handleRowClick,
  loading,
  pageLength,
  permission,
  handleStartDateChange,
  handleEndDateChange,
  formateStartDate,
  formateEndDate,
  createFunction,
  date,
  exportBtn,
  handleSearch,
  searchData,
  handleClear,
  value,
  searchValueRef,
  sortField,
  startdate,
  enddate,
  fetchData,
  report,
  
}) {
  // Define the sorting model state
  const [sortModel, setSortModel] = useState([
    { field: sortField, sort: "asc" },
  ]);

  useEffect(() => {
    setSortModel([{ field: sortField, sort: "asc" }]);
  }, [sortField]);

  return (
    <div className={classes.GridWrapper}>
      <h4>{heading}</h4>
      <DataGrid
        slots={{
          toolbar: () => (
            <CustomToolDataGrid
              permission={permission}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              formateStartDate={formateStartDate}
              formateEndDate={formateEndDate}
              createFunction={createFunction}
              date={date}
              handleSearch={handleSearch}
              searchData={searchData}
              handleClear={handleClear}
              value={value}
              searchValueRef={searchValueRef}
              startdate={startdate}
              enddate={enddate}
              fetchData={fetchData}
              report={report}
              exportBtn={exportBtn}
            />
          ),
          Pagination: GridPagination,
          QuickFilter: GridToolbarQuickFilter,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        sx={{
          "& .MuiTypography-root": {
            fontFamily: "Poppins, sans-serif !important",
          },
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            fontFamily: "Poppins, sans-serif !important",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-toolbarContainer": {
            flexDirection: "row-reverse",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButtonBase-root": {
            backgroundColor: "#ffbf0c",
            color: "white",
            padding: "8px 1rem",
            width: "100px",
            fontSize: "12px",
            fontFamily: "Poppins, sans-serif",
            textTransform: "capitalize",
          },
          "& .MuiDataGrid-panel": {
            transform: "translate3d(1357px, 254px, 0px) !important",
          },
          "& .MuiBadge-badge": {
            backgroundColor: "white",
            color: "#ffbf0c",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
          },
          "& .MuiButton-startIcon": {
            display: "none",
          },
          "& .MuiDataGrid-overlayWrapper": {
            height: "4rem !important",
          },
          "& .actions": {
            display: "grid",
            gridTemplateColumns: "1fr",
            gridGap: "1rem",
          },
          "& .MuiDataGrid-main": {
            height: "70vh",
          },
          "& .MuiFormControl-root": {
            height: "45px",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            fontFamily: "Poppins, sans-serif !important",
            fontWeight: "600",
            paddingLeft: "0.4rem",
            color:"#8E92BC"
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-actionsCell": {
            justifyContent: "normal",
          },
          "& .MuiDataGrid-cell": {
            paddingLeft: "1rem",
          },
          "& .MuiToolbar-regular":{
            paddingLeft: "1rem",
            paddingRight: "18px"
          },
        }}
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={handleRowClick}
        loading={loading}
        rowCount={pageLength}
        disableRowSelectionOnClick    /* Disables row selection when clicking on a row to prevent unintended selection, ensuring the focus remains on other row actions. */
        disableColumnSelector  /* Disables the option for users to toggle column visibility, maintaining a consistent column layout. */
        disableDensitySelector /* Disables the density selector which controls the row height, keeping a uniform density across all rows. */
        disableColumnFilter /* Disables the column filtering options to simplify the interface, especially when filtering isn't necessary. */
        getRowClassName={(params) => `super-app-theme--${params.row.status}`} /* Dynamically assigns a CSS class to each row based on its status, allowing conditional styling. */
        pageSizeOptions={[10, 25, 50, 100]} /* Provides a set of options for the number of rows per page, allowing users to choose how much data to display at once. */ 
        sortModel={sortModel} /* Specifies the current sort state, controlling the default sort order of the rows. */
        onSortModelChange={(model) => setSortModel(model)} /* Updates the sort model state when the user changes the sorting order, allowing for dynamic sorting. */
        disableColumnMenu /* Disables the column menu options like sorting, filtering, and column hiding, for a cleaner UI without advanced features. */
      />
    </div>
  );
}

export default DataGridSetup;
